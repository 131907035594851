@import '../../../shared/config/palette.scss';

.assetCheckoutWrapper {
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 5px;
    position: relative;
    top: 0;
    left: 700px;
    width: 100%;
    overflow: hidden;
    animation: SlideIn 0.3s ease-in-out;

    @keyframes SlideIn {
        from {
            left: 0px;
        }

        to {
            left: 700px;
        }
    }

    @media (max-width:$tablet-asset) {
        padding-left: 5px;
    }
}

.assetTopRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title {
        font-size: 28px;
        color: var(--menu-text, $menu-text);
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 10px;
    }

    .slider {
        padding: 0 15px;

        :global(.MuiSlider-root) {
            border-radius: 0px;
            border: var(--primary, $primary);

            :global(.MuiSlider-track) {
                height: 13px;
                background-color: var(--primary, $primary);
            }

            :global(.MuiSlider-rail) {
                height: 13px;
                background-color: var(--primary, $primary);
            }

            :global(.MuiSlider-thumb) {
                background-color: var(--primary, $primary);
                width: 30px;
                height: 30px;
                position: relative;

                &::after {
                    content: '';
                    background-color: var(--bg, $bg);
                    border-radius: 50%;
                    width: 14px;
                    height: 14px;
                    position: 'absolute';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .tokens {
        display: flex;
        align-items: center;
        color: var(--primary, $primary);
        font-size: 30px;
        margin-bottom: 16px;

        @media (max-width: 1250px) {
            flex-direction: column;
            align-items: flex-start;
        }

        @media (max-width: 768px) {
            flex-direction: row;
            align-items: center;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .amount {
            width: 70%;
            color: var(--primary, $primary);
            padding: 8px 10px;
            border: 1px solid var(--text-secondary, $text-secondary);
            font-weight: 700;
            margin-right: 12px;
            display: inline-block;
            font-size: 30px;
            outline: none;
            max-width: 220px;

            @media (max-width: 950px) {
                width: 100%;
            }

            @media (max-width: 600px) {
                width: 100%;
                margin-bottom: 20px;
                box-sizing: border-box;
            }

            &:focus {
                border-color: var(--primary, $primary);
            }

            &.red,
            &:focus.red {
                border-color: #f36969;
            }
        }
    }

    .remain {
        text-align: right;
        margin-top: 14px;
        font-size: 16px;
        color: var(--text-secondary, $text-secondary);

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .bottom {
        text-align: right;
        padding-top: 45px;
    }

    .total {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        color: var(--text, $text);

        .amount {
            color: var(--primary, $primary);
            margin-left: 9px;
        }
    }

    .checkout {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;

        .back {
            background: none;
            border: none;
            font-size: 18px;
            margin-right: 45px;
            text-decoration: underline;
            color: var(--text, $text);
            cursor: pointer;

            @media (max-width: $mobile-dashboard) {
                font-size: 14px;
            }

            @media (max-width: $mobile) {
                font-size: 16px;
            }
        }

        .begin {
            border-radius: 8px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary, $primary);
            color: var(--bg, $bg);
            transition: all 0.2s ease-out;
            padding: 12px 35px;
            font-weight: 700;
            cursor: pointer;

            img {
                margin-right: 8px;
            }

            &:hover {
                background-color: var(--primary-dark, $primary-dark);
                transition: all 0.2s ease-out;
            }

            &:disabled {
                background-color: var(--primary-dark, $primary-dark);
                color: var(--button-disabled, $button-disabled);
                cursor: not-allowed;
            }
        }
    }
}

.toCheckout {
    left: 0px;
    animation: SlideOut 0.3s ease-in-out;

    @keyframes SlideOut {
        from {
            left: 700px;
        }

        to {
            left: 0px;
        }
    }
}

.minimumTooltip {
    color: #f36969;
    font-size: 12px;
}