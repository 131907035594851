@import '../../../shared/config/palette.scss';

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
}

.topPanel {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px 0px 16px 16px;
    margin-right: 10px;

    .text {
        color: var(--menu-text, $menu-text);
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.table {
    @media (max-width:900px) {
        border-top: 1px solid var(--button-disabled, $button-disabled);
    }

    .titleRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);

        @media (max-width:$mobile) {
            justify-content: space-between;
        }

        .orderButton {
            padding: 5px;
        }

        .columnLeftTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 180px;
            min-width: 180px;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                width: fit-content;
            }
        }

        .rightsTitles {
            display: flex;
            justify-content: space-between;
        }

        .columnRightTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                width: fit-content;
            }
        }
    }

    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;

        .profileRow {
            display: flex;
            outline-color: white;
            border-bottom: 1px solid var(--button-disabled, $button-disabled);
            transition: all 0.2s linear;

            &:hover {
                outline: 1px solid var(--primary, $primary);
                background: #00bc6812;
            }

            @media (max-width:$mobile) {
                flex-direction: column;
            }

            .columnLeft {
                width: 180px;
                min-width: 180px;
                box-sizing: border-box;
                padding: 16px;
                color: var(--menu-text, $menu-text);

                .date {
                    font-size: 14px;
                    line-height: 20px;
                }

                .time {
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: var(--text-secondary, $text-secondary);
                }

            }

            .columnRight {
                display: flex;
                align-items: center;
                padding: 16px;
                font-size: 14px;
                line-height: 20px;

                @media (max-width:$mobile) {
                    word-break: break-all;
                }
            }
        }

        :global(.MuiPaper-root) {
            border-radius: 8px;

            :global(.MuiList-root) {
                li {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .pagination {
        color: var(--text-secondary, $text-secondary);
         
    }
}