@import '../../../shared/config/palette.scss';

.wrapper {
	display: flex;
	flex-direction: column;

	.block {
		display: flex;
		align-items: flex-end;
		margin-top: 8px;

		.right {
			margin-left: 30px;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

.addButton {
	width: max-content;
	color: var(--primary, $primary);
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	padding: 8px;
	margin-top: 8px;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	border: none;
	background: transparent;
	transition: background-color .3s linear;

	&:hover {
		background-color: var(--hover-green, $hover-green);
	}
}