@import '../../../shared/config/palette.scss';

.assetTopRightWrapper {
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 5px;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    animation: SlideIn 0.3s ease-in-out;
    @keyframes SlideIn {
        from {
            right: 700px;
        }
        to {
            right: 0px;
        }
    }
    @media (max-width: $tablet-asset) {
        padding: 0;
        padding-right: 5px;
    }
    .assetTopRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .raised {
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        color: var(--primary, $primary);
        margin-bottom: 4px;
        @media (max-width: $mobile-dashboard) {
            font-size: 24px;
            line-height: 29px;
        }
    }
    
    .percentage {
        color: var(--text-secondary, $text-secondary);
        font-size: 18px;
        margin-bottom: 8px;
    
        @media (max-width: $mobile-dashboard) {
            font-size: 16px;
        }
    
        .green {
            font-weight: 700;
            color: var(--primary, $primary);
        }
    }
    
    .infoField {
        padding: 9px 0 16px;
        margin-bottom: 0;
        transition: margin-bottom 0.3s ease-in-out;
        border-top: 1px solid var(--button-disabled, $button-disabled);
    
        .tooltipWrapper {
            &::after {
                display: none;
                position: absolute;
                content: " ";
                background-color: var(--bg, $bg);
                border-top: 1px solid var(--primary, $primary);
                border-left: 1px solid var(--primary, $primary);
                transform: rotate(45deg);
                width: 18px;
                height: 18px;
                z-index: 0;
                top: 21px;
                left: 167px;
            }
        }
    
        .value {
            font-size: 28px;
            color: var(--text, $text);
            font-weight: 700;
            margin-bottom: 4px;
    
            @media (max-width: $mobile-dashboard) {
                font-size: 24px;
            }
        }
    
        .field {
            font-size: 18px;
            color: var(--text-secondary, $text-secondary);
            position: relative;
    
            @media (max-width: $mobile-dashboard) {
                font-size: 16px;
            }
        }
    }
    
    .buy {
        border-radius: 8px;
        width: 100%;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border: none;
        font-size: 18px;
        font-weight: 700;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;

        @media (max-width: $tablet-asset) {
            padding: 10px 30px;
            margin-bottom: 10px;
        }
    
        @media (max-width: $mobile-dashboard) {
            margin-bottom: 20px;
            padding: 13px;
        }
    
        &:hover {
            background-color: var(--primary-dark, $primary-dark);
            transition: all 0.2s ease-out;
        }

        &:disabled{
            cursor: default;
            background-color: var(--button-disabled, $button-disabled);
        }
    
        img {
            margin-left: 8px;
        }
    }
    .error{
        color: var(--attention-red, $attention-red);
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 600;
        text-align: center;
    }
}
.toCheckout {
    right: 700px;
    animation: SlideOut 0.3s ease-in-out;
    @keyframes SlideOut {
        from {
            right: 0;
        }
        to {
            right: 700px;
        }
    }
}
