@import '../../../shared/config/palette.scss';

.title {
    font-size: 24px;
    font-weight: 600;
    color: var(--menu-text, $menu-text);
    line-height: 32px;
    margin-bottom: 24px;
}

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
    margin-bottom: 24px;
    .headRow{
        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);
        .imageRow{
            width: 10%;
            min-width: 100px;
        }
        th{
            padding: 16px 8px;
        }
        th:first-child{
            padding-left: 16px;
        }
    }
    .headCellDT{
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 133%;
        color: var(--text-secondary, $text-secondary);
    }
    .filter{
        input{
            padding: 6px 8px;
        }
    }
}

.createNew {
    display: inline-block;
    background: var(--primary, $primary);
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    color: var(--bg, $bg);
    border: none;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.topPanel {
    .text {
        color: var(--menu-text, $menu-text);
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.table {
    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;

        .profileRow {
            display: flex;
            outline-color: white;
            border-bottom: 1px solid var(--button-disabled, $button-disabled);

            @media (max-width: $tablet) {
                justify-content: space-between;
            }

            .columnLeft {
                max-width: 400px;
                box-sizing: border-box;
                padding: 16px;
                display: flex;
                align-items: center;
                img{
                    border-radius: 8px;
                    width: 80px;
                    height: 60px;
                    object-fit: cover;
                    margin-right: 20px;
                }
            }

            .columnRight {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                width: 100%;
                padding: 16px;
                font-size: 14px;
                line-height: 20px;
                .link{
                    text-decoration: none;
                    color: var(--primary, $primary);
                }
            }
        }

        :global(.MuiPaper-root) {
            border-radius: 8px;

            :global(.MuiList-root) {
                li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .pagination {
        color: var(--text-secondary, $text-secondary);
    }
}