@import "../../../shared/config/palette";

.wrapper{
  margin-top: 40px;
  background-color: var(--bg, $bg);
  border-radius: 8px;
  border: 1px solid var(--button-disabled, $button-disabled);
  padding: 24px;
  color: var(--text, $text);
  font-weight: 500;
  .tokenSymbol{
    color: var(--primary, $primary);
    font-weight: 600;

  }
}
.error{
  font-size: 14px;
  color: var(--attention-red, $attention-red);
  font-weight: 400;
  margin-top: -10px;
}