@import "../../../shared/config/palette";

.nestedContainer{
  margin: 16px auto;
  background: var(--bg, $bg);
  border: 1px solid var(--button-disabled, $button-disabled);
  border-radius: 8px;
  .MuiTableContainer-root{
    border-radius: 0px 0px 8px 8px;
  }
}