@import '../../../shared/config/palette';

.wrapper {
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 8px;
    background: var(--bg, $bg);
    border: 1px solid var(--button-disabled, $button-disabled);

    h3 {
        margin-bottom: 16px;
    }

    .colors {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .color {
            display: flex;
            margin-bottom: 8px;

            input[type='color'] {
                cursor: pointer;
            }

            p {
                margin-left: 10px;
                color: var(--text, $text)
            }
        }
    }

    .btns {
        display: flex;
        margin-bottom: 16px;
        margin-top: 8px;

        @media (max-width: $mobile) {
            flex-direction: column;
        }
    }

    .button {
        background: var(--primary, $primary);
        color: var(--bg, $bg);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all 0.3s linear;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:disabled {
            background-color: var(--button-disabled, $button-disabled);
        }

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }

        @media (max-width: $mobile) {
            width: 100%;
            padding: 8px 12px;
            margin-top: 8px;
        }
    }
    .successMessage{
        color: $primary;
    }
}