@import '../../../shared/config/palette.scss';

.assetPage {
    .back {
        display: inline-block;
        text-decoration: none;
        color: var(--text-secondary, $text-secondary);
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        transition: .3s;
        border-radius: 5px;
        margin-bottom: 16px;
    
        img {
            margin-right: 8px;
            height: 13px;
            width: 13px;
        }
    
        &:hover {
            background-color: var(--input-bg, $input-bg);
        }
    }
    .content {
        margin-top: 24px;
        background: var(--bg, $bg);
        border-radius: 8px;
        border: 1px solid var(--button-disabled, $button-disabled);
        display: flex;
        justify-content: space-between;
        padding: 20px;
        @media(max-width: $tablet) {
            flex-direction: column;
        }

        .left {
            width: 60%;
            @media(max-width: $tablet) {
                order: 2;
                width: 100%;
            }
        }

        .right {
            width: 37%;
            @media(max-width: $tablet) {
                order: 1;
                width: 100%;

                .investorPerks,
                .backToTop {
                    display: none;
                }
            }
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: var(--button-disabled, $button-disabled);
        margin: 20px 0;
    }

    .backToTop {
        position: fixed;
        right: 10px;
        bottom: 10px;
        display: inline-block;
        z-index: 99;
        margin-top: 16px;
        border-radius: 8px;
        background-color: var(--primary, $primary);
        padding: 5px 10px;
        color: var(--bg, $bg);
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        border: none;
        transition: background-color .3s linear;
        span{
            display: inline-block;
            transform: rotate(-90deg);
        }
        &:hover {
            background-color: var(--primary-dark, $primary-dark);
        }
    }
}