@import '../../../shared/config/palette.scss';

.title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.back {
    display: inline-block;
    text-decoration: none;
    color: var(--text-secondary, $text-secondary);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    transition: .3s;
    border-radius: 5px;
    margin-bottom: 30px;

    img {
        margin-right: 8px;
        height: 13px;
        width: 13px;
    }

    &:hover {
        background-color: var(--input-bg, $input-bg);
    }
}

.block {
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);

    .formPart {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--button-disabled, $button-disabled);

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .title {
        color: var(--menu-text, $menu-text);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .inputLabel {
        color: var(--text-secondary, $text-secondary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
        margin-top: 16px;
    }

    .select {
        width: 100%;
        border-radius: 8px;
        margin-top: 0px;
        margin-bottom: 0;
        background-color: var(--input-bg, $input-bg);
        border-radius: 8px;
        color: var(--menu-text, $menu-text);

        fieldset {
            border: 1px solid var(--input-bg, $input-bg);
            border-radius: 8px;
        }
    }

    :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
        border-color: var(--primary, $primary);
    }

    .inputsBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.btns {
    position: fixed;
    bottom: 0px;
    left: 272px;
    width: calc(100vw - 272px);
    padding: 24px 48px 0px;
    border-top: 1px solid var(--button-disabled, $button-disabled);
    background: var(--bg, $bg);

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;

    @media (max-width: $mobile-dashboard) {
        width: 100%;
        position: static;
        background: none;
        border-top: none;
        padding: 24px 0px 0px;
    }

    .btn {
        background: var(--primary, $primary);
        color: var(--bg, $bg);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }
    }

    .btnEmpty {
        background: transparent;
        color: var(--primary, $primary);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
            background: var(--hover-green, $hover-green);
        }
    }
}

.errors {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.errorText {
    margin: 0;
    color: $attention-red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}

.sucessText {
    margin: 0;
    color: var(--primary, $primary);
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}

.listHighlights {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .perk {
        display: flex;
        padding-bottom: 16px;

        .left {
            width: 100%;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid var(--button-disabled, $button-disabled);
            position: relative;

            .right {
                position: absolute;
                top: 5px;
                right: 5px;
                height: 100%;
                padding-bottom: 10px;
            }
        }
    }
}

.addButton {
    width: max-content;
    color: var(--primary, $primary);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    transition: background-color .3s linear;

    &:hover {
        background-color: var(--hover-green, $hover-green);
    }
}