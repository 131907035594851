@import '../../../shared/config/palette.scss';

.assetDocuments {
    border-radius: 8px;
    background-color: var(--light-green, $light-green);
    border: 1px solid var(--primary, $primary);
    padding: 25px 32px;
    margin-bottom: 30px;
    @media(max-width: $mobile-dashboard) {
      padding: 20px 10px;
    }
  
    .title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 16px;
      color: var(--primary, $primary);
  
      @media(max-width: $mobile-dashboard) {
        font-size: 24px;
      }
    }
  
    .document {
      background: none;
      border: none;
      width: 100%;
      display: flex;
      padding: 20px 0;
      border-top: 1px solid var(--primary, $primary);
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
  
      @media(max-width: $mobile-dashboard) {
        font-size: 16px;
      }
  
      .img, img {
        width: 18px;
        margin-right: 21px;
        stroke: var(--primary, $primary);
      }
  
    }
    .disabled{
      cursor: text;
      color: var(--button-disabled, $button-disabled);
    }
  }