@import '../../../shared/config/palette.scss';

.uploader {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;

	.listFiles {
		margin-bottom: 0px;
	}

	.upload {
		padding: 26px 20px 16px;
		border-radius: 8px;
		background: var(--input-bg, $input-bg);
		outline: 2px dashed var(--text-secondary, $text-secondary);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: relative;
		transition: all 0.4s linear;

		.error {
			z-index: 9;
			top: -2px;
			left: -2px;
			width: calc(100% + 4px);
			max-height: 800px;
			overflow: hidden;
			position: absolute;
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
			transition: 0.3s;

			.container {
				border: 2px solid $attention-red;
				padding: 14px;
				background: $secondary-red;
				display: flex;
				flex-direction: column;
				align-items: center;

				&>header {
					margin-top: 9px;
					color: $attention-red;
					font-family: Montserrat;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: center;
				}

				&>p {
					font-family: Montserrat;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: center;
					max-width: 270px;
				}

				.close {
					position: absolute;
					top: 10px;
					right: 10px;
					height: 16px;
					width: 16px;
					font-family: Montserrat;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 22px;
					letter-spacing: 0em;
					text-align: center;
					color: $attention-red;
				}
			}

			&.hidden {
				max-height: 0;
			}
		}

		.text {
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			margin-top: 18px;
		}

		.browse {
			background: var(--primary, $primary);
			border-radius: 8px;
			border: none;
			color: var(--bg, $bg);
			font-weight: bold;
			font-size: 12px;
			line-height: 16px;
			padding: 12px 24px;
			transition: 0.3s;
			margin-top: 8px;
		}

		&:hover .browse {
			background: var(--primary-dark, $primary-dark);
		}

		input {
			display: block;
			width: 100%;
			border: none;
			text-transform: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: all 0.3s linear;

			&:hover {
				cursor: pointer;
			}

			&:focus {
				outline: none;
			}
		}

		input:hover~img {
			animation: startUpload 1.5s alternate ease-in-out infinite;
		}

		@keyframes startUpload {
			0% {
				transform: scale(1);
			}

			100% {
				transform: scale(1.1);
			}
		}

		.help {
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			margin-top: 8px;
			color: var(--text-secondary, $text-secondary);
		}
	}

	.drag {
		background: var(--hover-green, $hover-green);
		outline-offset: -5px;

		img {
			position: relative;
			animation: drag 1s alternate ease-in-out infinite;
		}

		@keyframes drag {
			0% {
				transform: scale(1);
			}

			100% {
				transform: scale(1.1);
			}
		}
	}

	.listFiles {
		display: flex;
		flex-direction: column;
		.file {
			display: flex;
			padding: 8px 17px;
			margin-top: 4px;
			margin-bottom: 4px;
			align-items: center;
			justify-content: space-between;
			border-radius: 8px;
			outline: 2px dashed var(--text-secondary, $text-secondary);
			transition: all .3s linear;

			&:hover {
				background: $hover-green
			}

			.left {
				display: flex;
				align-items: center;
				.name {
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					margin-right: 8px;
					color: var(--menu-text, $menu-text);
					word-break: break-all;
				}
				img {
					margin-right: 9px;
				}
				span {
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					margin-right: 5px;
					color: var(--text-secondary, $text-secondary);
				}
				.description{
					display: flex;
					flex-direction: column;
					span {
						word-break: break-all;
					}
				}
			}
			.right{
				display: flex;
				align-items: center;
			}
		}
	}
}

.addButton {
	width: max-content;
	color: var(--primary, $primary);
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	border: none;
	background: transparent;
	transition: background-color .3s linear;

	&:hover {
		background-color: var(--hover-green, $hover-green);
	}
}