@import "../../shared/config/palette";


.title{
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--menu-text, $menu-text);
}

.time{
  font-weight: 400;
  font-size: 12px;
  color: var(--text-secondary, $text-secondary);
}

.back {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: var(--text-secondary, $text-secondary);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  img{
      margin-right: 10px;
  }
}