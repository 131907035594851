@import "../../../../shared/config/palette";

.wrapper{
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  border-bottom: 1px solid var(--button-disabled, $button-disabled);
  color: var(--menu-text, $menu-text);
  .image{
    position: relative;
    img{
      max-width: 100%;
      height: 60px;
      border-radius: 8px;
    }
    .load{
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }

  .name{
    color: var(--menu-text, $menu-text);
  }
  .domains{
    color: var(--menu-text, $menu-text);

  }
  .portalName{
    color: var(--menu-text, $menu-text);

  }
  .buttons{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .link{
    border: none;
    background: none;
    outline: none;
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: var(--primary, $primary);
    font-weight: 400;
  }
}