@import "../../../shared/config/palette";

.wrapperDT {
  border-radius: 8px;

  .emptyBlockDT {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary, $primary);
  }

  .tableHeadDT {
    background-color: var(--input-bg, $input-bg);
    border-radius: 0;

    tr {
      th {
        padding: 16px 8px;
      }

      th:first-child {
        padding-left: 16px;
      }

      th:last-child {
        padding-right: 16px;
      }
    }
  }

  .headCellDT {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 133%;
    color: var(--text-secondary, $text-secondary);
  }

  .tableRowDT {
    transition: all 0.2s linear;
    vertical-align: top;

    &:hover {
      outline: 1px solid var(--primary, $primary);
      background: var(--light-green, $light-green);
    }

    td {
      padding: 16px 8px;
    }

    td:first-child {
      padding-left: 16px;
    }

    td:last-child {
      padding-right: 16px;
    }
  }

  .cellDT {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 142.8%;
    color: var(--menu-text, $menu-text);
  }

  .nameCell {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 128.5%;
    color: var(--text-dark, $text-dark);
  }

  .email {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 133%;
    color: var(--text-secondary, $text-secondary);
  }
}

.singleTable {
  background-color: var(--bg, $bg);
  border: 1px solid var(--button-disabled, $button-disabled);
  margin-top: 24px;
}