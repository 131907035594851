$primary: #00BC68;
$primary-dark: #0DA75D;

$bg: #ffffff;

$text: #27303E;
$text-dark: #000000;
$menu-text: #0F1014;
$text-secondary: #8D8D8D;
$button-disabled: #D2D2D2;
$hover-green: #E5F8E8;
$input-bg: #F3F3F4;
$text-grey: #7D7D7D;

$attention-red: #F05555;
$secondary-red: #F6CDCD;

$attention-yellow: #F5BF00;

$completed-green: #00BC68;

$mobile: 640px;
$mobile-dashboard: 730px;
$tablet: 1124px;

@function highlight($color) {
    @return transparentize($color, 0.2);
}