@import '../../shared/config/palette.scss';

.wrapper {
  box-sizing: border-box;
  @media (max-width:900px) {
    width: 100%;
  }
  .submitButton {
    outline: none;
    border: none;
    width: auto;
    padding: 16px 32px;
    background-color: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: .3s background-color linear;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-dark, $primary-dark);
    }

    &:disabled {
      color: var(--button-disabled, $button-disabled);
      background: var(--input-bg, $input-bg);
    }
    @media (max-width:900px) {
      justify-content: center;
      width: 100%;
      margin-top: 16px;
    }
  }
}

.dialog {
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 560px;
  box-sizing: border-box;
  @media (max-width:$mobile) {
    min-width: auto;
  }
  .closeIcon {
    position: absolute;
    top: 29px;
    right: 29px;
    @media (max-width:$mobile) {
      position: static;
      margin-top: 10px;
    }
  }
  .title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--button-disabled, $button-disabled);
  }
  .text {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .actions{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .submitButton {
    outline: none;
    border: none;
    width: 100%;
    margin-top: 24px;
    padding: 16px 32px;
    background-color: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    margin: 0 5px;
    transition: background-color .3s linear;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-dark, $primary-dark);
    }

    &:disabled {
      color: var(--button-disabled, $button-disabled);
      background-color: var(--input-bg, $input-bg);
      cursor: default;
    }
  }
  .input {
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 0;
    :global(.MuiOutlinedInput-root) {
      background-color: var(--input-bg, $input-bg);
      border-radius: 8px;
    }
    p {
      margin: 0;
      color: $attention-red;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
    }
    fieldset {
      border: 1px solid var(--input-bg, $input-bg);
      border-radius: 8px;
    }
    :global(.Mui-focused) fieldset {
      border-color: var(--primary-dark, $primary-dark);
    }
    input {
      height: 24px;
      padding: 16px;
      font-size: 14px;
    }
  }
}

.errorText {
  margin: 0;
  color: $attention-red;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
}
