@import '../../../shared/config/palette';

.titleForBlock{
  font-weight: 600;
  font-size: 1.125rem;
  color: var(--text-dark, $text-dark);
  line-height: 1.222;
}

.title{
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--menu-text, $menu-text);
  margin-bottom: 24px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.link{
  font-weight: 500;
  color: var(--primary, $primary);
}

.errorText {
  margin: 0;
  color: $attention-red;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
}