@import '../../../shared/config/palette';

.title {
  color: var(--menu-text, $menu-text);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--bg, $bg);
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid var(--button-disabled, $button-disabled);

  .filterPanel {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .wrapper {
    box-shadow: none;
    border-radius: 8px 8px 0 0;

    .tableHead {
      background-color: var(--input-bg, $input-bg);
      border-radius: 8px;

      tr {
        th {
          th {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            background: var(--input-bg, $input-bg);
            padding: 16px 20px;
            color: var(--text-secondary, $text-secondary);

          }

          .flexHead {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 133%;
            color: var(--text-secondary, $text-secondary);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .left {
            justify-content: flex-start;
          }
        }

        th:first-child {
          padding-left: 16px;
        }

        th:last-child {
          padding-right: 16px;
        }
      }
    }

    .body {
      position: relative;
    }

    .loading {
      background-color: var(--bg, $bg);
    }

    .emptyBlock {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: var(--primary, $primary);
    }

    .tableRow {
      transition: all 0.2s linear;
      vertical-align: top;

      &:hover {
        outline: 1px solid var(--primary, $primary);
        background: var(--light-green, $light-green);
      }

      td {
        padding: 16px;
      }

      td:first-child {
        padding-left: 16px;
      }

      td:last-child {
        padding-right: 16px;
      }
    }

    .nameCell {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 128.5%;
      color: var(--text-dark, $text-dark);
    }

    .email {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 133%;
      color: var(--text-secondary, $text-secondary);
    }

    .cellPadding {
      padding-bottom: 32px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .cell {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 142.8%;
      color: var(--menu-text, $menu-text);
    }
  }
}