@import '../../../shared/config/palette.scss';

.assetContent {
    .title {
        font-size: 28px;
        line-height: 28px;
        color: var(--menu-text, $menu-text);
        font-weight: 700;
        margin: 25px 0 19px;

        @media (max-width: $mobile-dashboard) {
            font-size: 24px;
        }
    }

    .subtitle {
        font-size: 18px;
        font-weight: 700;
        color: var(--primary, $primary);
        line-height: 40px;
        margin-bottom: 5px;

        @media (max-width: $mobile-dashboard) {
            font-size: 16px;
        }
    }

    .text {
        font-size: 18px;
        line-height: 40px;
        color: var(--text-secondary, $text-secondary);
        border-bottom: 1px solid var(--button-disabled, $button-disabled);
        padding-bottom: 20px;

        span.smaller {
            font-size: 15px;
        }

        span.green {
            color: var(--primary, $primary);
            font-weight: 700;
        }

        ul,
        li {
            margin-left: 20px;
        }

        h2 {
            color: var(--menu-text, $menu-text);
        }

        a {
            color: var(--primary, $primary);
        }

        @media (max-width: $mobile-dashboard) {
            font-size: 16px;
            line-height: 32px;
        }
    }

    .text-title {
        font-weight: 700;
        color: var(--text-secondary, $text-secondary);
    }

    .image1 {
        margin: 25px 0 19px;
        width: 100%;

        img {
            border-radius: 8px;
            width: 100%;
        }
    }

    .assetImage {
        width: 100%;
        position: relative;

        iframe {
            width: 100%;
            height: 406px;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: var(--button-disabled, $button-disabled);
        margin: 20px 0;
    }

    .howWorks {
        border-radius: 8px;
        background-color: var(--light-green, $light-green);
        border: 1px solid var(--primary, $primary);
        margin: 20px 0 37px;
        padding: 20px 45px;

        .worksTitle {
            font-size: 32px;
            font-weight: 700;
            color: var(--primary, $primary);
            text-align: left;

            @media (max-width: $mobile-dashboard) {
                font-size: 24px;
            }
        }

        .worksSubtitle {
            font-size: 22px;
            max-width: 80%;

            @media (max-width: $mobile-dashboard) {
                font-size: 16px;
            }
        }

        .top {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: none;
            border: none;
        }

        .arrow {
            position: absolute;
            top: 20%;
            right: 0;
            transform-origin: center;
            transition: all 0.2s ease-in;
            transform: rotate(360deg);
            fill: var(--primary, $primary);

            &.active {
                transform: rotate(540deg);
                transition: all 0.2s ease-in;
            }
        }

        .dropdown {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            &.shown {
                max-height: 5000px;
                transition: all 0.5s ease-out;
            }
        }

        .worksItem {
            display: flex;
            padding: 35px 0;
            border-bottom: 1px solid var(--primary, $primary);
            align-items: center;

            &:last-of-type {
                border-bottom: none;
            }

            img {
                max-width: 110px;
                margin-right: 45px;

                @media (max-width: $mobile-dashboard) {
                    max-width: 40px;
                    margin-right: 25px;
                }

                @media (max-width: 360px) {
                    display: none;
                }
            }

            .itemTitle {
                font-size: 28px;
                font-weight: 700;
                color: var(--primary, $primary);
                margin-bottom: 15px;

                @media (max-width: $mobile-dashboard) {
                    font-size: 20px;
                }
            }

            .itemDescr {
                font-size: 22px;
                line-height: 130%;

                @media (max-width: $mobile-dashboard) {
                    font-size: 16px;
                }
            }
        }
    }

    .reviewsSlider {
        margin: 15px 0;
        position: relative;

        :global(.testimonials-forward) {
            fill: var(--primary, $primary);
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            z-index: 1000;
            cursor: pointer;

            @media (max-width: $mobile-dashboard) {
                top: initial;
                left: initial;
                right: 37%;
                bottom: -70px;
            }
        }

        :global(.testimonials-back) {
            fill: var(--primary, $primary);
            position: absolute;
            left: 10px;
            top: 50%;
            z-index: 1000;
            transform: translateY(-50%);
            cursor: pointer;

            @media (max-width: $mobile-dashboard) {
                top: initial;
                left: 37%;
                right: initial;
                bottom: -70px;
            }
        }

        .reviewsSwiper {
            width: 100%;
            max-width: 100%;
            border-radius: 8px;
            background-color: var(--light-green, $light-green);
            border: 1px solid var(--primary, $primary);

            @media (max-width: $mobile-dashboard) {
                margin-bottom: 80px;
            }
        }

        .slide {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
        }

        .slideInner {
            width: 80%;
            margin: 0 auto;
            text-align: center;
            padding: 50px 0 40px;

            @media (max-width: $mobile-dashboard) {
                padding: 30px 10px;
                width: 100%;
                box-sizing: border-box;
            }

            .review {
                font-size: 28px;
                line-height: 32px;
                font-weight: 700;
                margin-bottom: 12px;

                @media (max-width: $mobile-dashboard) {
                    font-size: 16px;
                    line-height: 27px;
                }
            }

            .name {
                font-size: 18px;
                font-weight: 700;
                color: var(--primary, $primary);

                @media (max-width: $mobile-dashboard) {
                    font-size: 18px;
                }
            }
        }
    }

}