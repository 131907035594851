@import '../../../shared/config/palette.scss';

.profileRow {
  display: flex;
  outline-color: white;
  border-bottom: 1px solid var(--button-disabled, $button-disabled);
  transition: all 0.2s linear;

  &:hover {
    outline: 1px solid var(--primary, $primary);
    background: var(--light-green, $light-green);
  }

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  .done {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: $primary;
    border-radius: 50%;
    margin-left: 15px;

    @media (max-width: $tablet) {
      margin-left: 0;
    }
  }

  .undone {
    @extend .done;
    background-color: var(--attention-red, $attention-red);
  }

  .columnLeft {
    width: 236px;
    box-sizing: border-box;
    padding: 16px;

    @media (max-width: $tablet) {
      width: 100%;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: var(--text-dark, $text-dark);
      margin-bottom: 8px;
      word-break: break-all;
    }

    .email {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: var(--text-secondary, $text-secondary);

      .emailText {
        position: relative;
        width: 194px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: $tablet) {
          width: auto;
          display: inline-flex;
          white-space: normal;
          overflow: visible;
          text-overflow: inherit;
          word-break: break-all;
        }
      }

      button {
        background-color: transparent;
        border-radius: 8px;
        border: none;
        margin-left: 8px;
        cursor: pointer;
        transition: background-color 0.3s linear;

        &:active {
          background-color: var(--primary, $primary);
        }
      }
    }
  }

  .columnRight {
    width: calc(100% - 250px);
    padding: 0px 0px 0px 8px;
    display: grid;
    grid-template-columns: repeat(3, minmax(70px, 1fr));
    grid-column-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $tablet) {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 16px;
      padding-left: 16px;
      display: flex;
      flex-wrap: wrap;
    }

    .itemColStatus {
      display: flex;
      justify-content: flex-start;

      @media (max-width: $tablet) {
        flex: 1 1 50%;
      }
    }

    .itemColType {
      display: flex;
      align-items: center;
      color: var(--text, $text);
      word-break: break-all;
      font-size: 14px;
      line-height: 17px;

      @media (max-width: $tablet) {
        flex: 0 0 50%;
        margin: 5px 0;
      }

      .walletText {
        font-size: 13px;
      }

      .mobileDescription {
        color: var(--text, $text);
        font-size: 13px;
        margin-right: 5px;
        display: none;

        @media (max-width: $tablet) {
          display: inline;
        }
      }
    }
  }
}