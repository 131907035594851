@import "../../shared/config/palette";

.wrapper{
  background-color: var(--bg, $bg);;
  border-radius: 8px;
  border: 1px solid var(--button-disabled, $button-disabled);
  .header{
    background-color: var(--input-bg, $input-bg);
    padding: 17px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.17;
    color: var(--text-secondary, $text-secondary);
  }
  .name{
    flex: 0 1 250px;
  }
  .status{
    flex-grow: 1;
    padding-left: 8px;
  }
  @media (max-width: $tablet) {
    .name{
      flex: 1 1 auto;
    }
    .status{
      flex: 0 1 auto;
    }
  }
}