@import "../../../shared/config/palette";

.defaultBtn{
  background-color: var(--primary, $primary);
  transition: all .3s linear;
  &:hover{
    background-color: var(--primary-dark, $primary-dark);
  }
  &:disabled {
    color: var(--button-disabled, $button-disabled);
    background-color: var(--input-bg, $input-bg);
  }
}