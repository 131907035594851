@import '../../shared/config/palette';
.wrapper{
    width: 100%;
    position: relative;
    .mainBlock{
        margin-left: 272px;
        margin-top: 56px;
        background: var(--input-bg, $input-bg);
        @media (max-width: $mobile-dashboard) {
            margin-left: 0;
        }
        .wrapper{
            box-sizing: border-box;
            width: 100%;
            min-height: calc(100vh - 56px);
            padding: 48px 48px 100px;
            max-width: 1300px;
            margin: 0 auto;

            @media (max-width:$mobile-dashboard) {
                padding: 24px 20px 110px;
            }
        }
    }
    .fullBlock{
        background: var(--input-bg, $input-bg);
        margin-top: 56px;
        .wrapper{
            box-sizing: border-box;
            width: 100%;
            min-height: calc(100vh - 56px);
            padding: 48px 48px 100px;
            max-width: 1300px;
            margin: 0 auto;

            @media (max-width:$mobile) {
                padding: 24px 20px 110px;
            }
        }
    }
}