@import '../../../../shared/config/palette.scss';

.dialog {
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 560px;
  box-sizing: border-box;
  word-break: break-word;
  text-align: center;

  @media (max-width:$mobile) {
    min-width: auto;
  }

  .closeIcon {
    position: absolute;
    top: 29px;
    right: 29px;

    @media (max-width:$mobile) {
      position: static;
      margin-top: 10px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--menu-text, $menu-text);
    text-align: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--button-disabled, $button-disabled);
  }

  .time {
    font-size: 12px;
    color: var(--text-secondary, $text-secondary)
  }

  .smallTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 10px;
  }
  
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .cancelButton {
      outline: none;
      border: none;
      width: 100%;
       
      margin-top: 24px;
      padding: 16px 32px;
      background-color: var(--input-bg, $input-bg);
      color: var(--menu-text, $menu-text);
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      margin: 0 5px;
      box-shadow: none;
      transition: background-color .3s linear;

      &:hover {
        cursor: pointer;
        background-color: var(--button-disabled, $button-disabled);
      }

    }
  }
}