@import '../../../shared/config/palette.scss';

.mapArea {
  margin-top: 20px;
    .title {
      display: inline-block;
      color: var(--menu-text, $menu-text);
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .mapImg {
      border-radius: 8px;
      width: 100%;
    }
  }