@import '../../shared/config/palette.scss';

.title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.back {
    display: inline-block;
    text-decoration: none;
    color: var(--text-secondary, $text-secondary);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    transition: .3s;
    border-radius: 5px;
    margin-bottom: 30px;

    img {
        margin-right: 8px;
        height: 13px;
        width: 13px;
    }

    &:hover {
        background-color: var(--input-bg, $input-bg);
    }
}

.formPart {
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 8px;
    background: var(--bg, $bg);
    border: 1px solid var(--button-disabled, $button-disabled);

    .imgWrapper {
        position: relative;
        margin-top: 10px;
        border-radius: 8px;
        padding: 10px;
        border: 2px dashed var(--primary, $primary);
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            max-width: 40%;
            max-height: 200px;
            object-fit: cover;
            border-radius: 8px;
        }

        a {
            color: var(--primary, $primary);
            display: flex;
            align-items: center;

            img {
                margin-right: 5px;
            }
        }


        button {
            max-width: max-content;
            border-color: $attention-red;
            color: $attention-red;
            text-transform: inherit;
            font-family: Montserrat;

            &:hover {
                background-color: rgba(240, 85, 85, 0.06);
            }

            &:disabled {
                cursor: default;
                background-color: var(--button-disabled, $button-disabled);
            }
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;

        .block {
            display: flex;
            align-items: flex-end;
            margin-top: 8px;

            .right {
                margin-left: 30px;
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        .listHighlights {
            display: flex;
            flex-direction: column;
            margin-top: 8px;

            .review {
                display: flex;
                padding-bottom: 16px;
    
                .left {
                    width: 100%;
                    padding: 10px;
                    border-radius: 8px;
                    border: 1px solid var(--button-disabled, $button-disabled);
                    position: relative;
                    .right{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        height: 100%;
                        padding-bottom: 10px;
                    }
                }
            }
            
            .media {
                display: flex;
                padding-bottom: 16px;

                .left {
                    width: 100%;
                    padding: 10px;
                    padding-top: 40px;
                    border-radius: 8px;
                    border: 1px solid var(--button-disabled, $button-disabled);
                    position: relative;

                    .right {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        border-radius: 0 0 0 8px;
                        border-bottom: 1px solid var(--button-disabled, $button-disabled);
                        border-left: 1px solid var(--button-disabled, $button-disabled);
                    }
                }
            }

            .highlight {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .left {
                    width: calc(100% - 50px);
                }
            }
        }
    }

    .addButton {
        width: max-content;
        color: var(--primary, $primary);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        padding: 8px;
        margin-top: 8px;
        border-radius: 8px;
        cursor: pointer;
        outline: none;
        border: none;
        background: transparent;
        transition: background-color .3s linear;

        &:hover {
            background-color: var(--hover-green, $hover-green);
        }
    }
}

.title {
    color: var(--menu-text, $menu-text);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
}

.text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;
}

.inputLabel {
    color: var(--text-secondary, $text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 16px;
}

.select {
    width: 100%;
    border-radius: 8px;
    margin-top: 0px;
    margin-bottom: 0;
     
    background-color: var(--input-bg, $input-bg);
    border-radius: 8px;
    color: var(--menu-text, $menu-text);

    fieldset {
        border: 1px solid var(--input-bg, $input-bg);
        border-radius: 8px;
    }
}

:global(.MuiOutlinedInput-root.Mui-focused) fieldset {
    border-color: var(--primary, $primary);
}

.inputsBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .checkbox{
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-size: 14px;
    }
    :global(.MuiCheckbox-colorPrimary.Mui-checked) {
        color: var(--primary, $primary);
    }
}


.errors {
    display: flex;
    flex-direction: column;
}

.errorText {
    margin: 0;
    color: $attention-red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}

.buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
}

.submitButton {
    margin-top: 10px;
    outline: none;
    border: none;
    width: auto;
     
    padding: 12px 24px;
    background-color: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: .3s background-color linear;

    &:hover {
        cursor: pointer;
        background-color: var(--primary-dark, $primary-dark);
    }

    &:disabled {
        color: var(--button-disabled, $button-disabled);
        background: var(--input-bg, $input-bg);
        cursor: default;
    }

    @media (max-width:900px) {
        justify-content: center;
        width: 100%;
        margin-top: 16px;
    }
}

.deleteBtn {
    color: $attention-red;
    background: var(--bg, $bg);
    border: 1px solid $attention-red;
    &:hover {
        cursor: pointer;
        background-color: var(--button-disabled, $button-disabled);
    }
}