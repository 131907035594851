@import "../../shared/config/palette.scss";

.blockWrapper{
  h3{
    margin-left: 10px;
    margin-bottom: 9px;
  }

  .nested{
    padding: 21px 43px  21px 25px;
    border-radius: 10px;
    background: var(--bg, $bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  .btnAction{
    background-color: var(--primary, $primary);
    transition: all linear 0.2s;
    outline: 0;
    border: 0;
    border-radius: 8px;
    padding: 11px 12px;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--bg, $bg);
    cursor: pointer;

    &:hover{
      background-color: var(--primary-dark, $primary-dark);
    }

    &:disabled{
      cursor: default;
      background-color: var(--button-disabled, $button-disabled);

    }

  }
}

.securityWrapper{
  flex: 1 1 auto;
  font-weight: 600;
}

