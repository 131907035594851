@import '../../../shared/config/palette.scss';

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
}

.topPanel {
    padding: 16px 0px 16px 16px;
    margin-right: 10px;

    .text {
        color: var(--menu-text, $menu-text);
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 8px;
    }

    .button {
        outline: none;
        border: none;
        width: auto;
        padding: 12px 24px;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border-radius: 8px;
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        text-decoration: none;
        display: inline-block;
        box-sizing: border-box;
        transition: .3s background-color linear;
        margin-right: 5px;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-dark, $primary-dark);
        }

        &:disabled {
            color: var(--button-disabled, $button-disabled);
            background: var(--input-bg, $input-bg);
        }

        @media (max-width:900px) {
            justify-content: center;
            width: 100%;
            margin-top: 16px;
        }
    }
}