.modalWrapper{
  flex: 0 1 85%;
  overflow-y: auto;

  .contract{
    width: 100%;
    height: 100%;
    min-height: 650px;
    border: none;
    position: relative;
    z-index: 531;
  }
}