@import '../../../shared/config/palette.scss';

.title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    color: var(--menu-text, $menu-text);
    margin-bottom: 24px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
}

.text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.balances {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    .noTokens {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--primary, $primary);
        align-self: center;
    }

    .balanceBlock {
        box-sizing: border-box;
        background: var(--bg, $bg);
        display: flex;
        align-items: center;
        min-width: 150px;
        margin-right: 16px;
        margin-bottom: 16px;

        padding: 16px;
        border-radius: 8px;

        @media (max-width: $mobile) {
            width: 100%;
        }

        .blockTitle {
            color: var(--text-secondary, $text-secondary);
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 7px;
        }

        .balance {
            word-break: break-all;
        }

        .img {
            background-color: var(--primary, $primary);
            padding: 16px 20px;
            border-radius: 50%;
            margin-right: 8px;
        }
    }
}

.blocks {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}

.whiteBlock {
    box-sizing: border-box;
    width: 100%;
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: $mobile-dashboard) {
        align-items: center;
    }

    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;

    .blockTitle {
        color: var(--text-secondary, $text-secondary);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 7px;
    }

    .block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $tablet-asset) {
            flex-direction: column;
            height: 100%;
        }

        .buttons {
            display: flex;
            margin-left: 16px;

            @media (max-width: $tablet-asset) {
                margin-top: 16px;
                margin-left: 0;
            }
        }
    }
}

.fiat {
    width: 38%;
    margin-right: 15px;
    min-width: 300px;

    @media (max-width: $tablet) {
        width: 100%;
        min-width: 100%;
    }

    .balance {
        word-break: break-all;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
    }
}

.crypto {
    width: 60%;

    @media (max-width: $tablet) {
        width: 100%;
    }

    .text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        word-break: break-all;
    }
}

.warning {
    width: max-content;
    margin-bottom: 16px;
    padding: 12px 24px;
    background: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    position: relative;
    z-index: 2;
    &::after{
        z-index: 1;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background: var(--primary, $primary);
        content: '';
        bottom: -10px;
        left: 20%;
        transform: rotate(45deg);
    }
}