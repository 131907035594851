@import '../../../../shared/config/palette.scss';

.slider {
    margin: 0 -8px;
    position: relative;
    margin-bottom: 24px;

    .nav {
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.17);
        z-index: 1;
        background: var(--input-bg, $input-bg);
        transition: .3s;
        filter: grayscale(100%);

        &.next {
            left: unset;
            right: -16px;
        }

        &:disabled {
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            filter: none;
        }
    }

    :global(.slick-list) {
        z-index: 0;

        .slider_item {
            display: flex;
            flex-direction: column;
            margin: 0 8px;
            background: var(--bg, $bg);
            background: #FFFFFF;
            border: 1px solid var(--button-disabled, $button-disabled);
            border-radius: 8px;
            padding: 16px;
            // min-height: 315px;
            min-height: 215px;

            header {
                display: flex;
                align-items: center;

                img {
                    height: 40px;
                    width: 40px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                .img {
                    background-color: var(--primary, $primary);
                    padding: 16px 20px;
                    text-transform: uppercase;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                .name {
                    font-weight: 600;

                    .symbol {
                        color: var(--text-secondary, $text-secondary);
                    }
                }
            }

            main {
                margin-top: 16px;

                .funded,
                .funding {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--primary, $primary);
                }

                .funding {
                    color: $dark-orange
                }

                .field {
                    .name {
                        margin-top: 16px;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--menu-text, $menu-text);
                    }

                    .value {
                        margin-top: 8px;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 32px;
                        color: var(--menu-text, $menu-text);
                    }
                }
            }

            footer {
                margin-top: auto;
                display: flex;
                justify-content: stretch;
                gap: 8px;

                button {
                    text-transform: none;
                    background-color: var(--input-bg, $input-bg);
                    color: var(--menu-text, $menu-text);
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0em;
                    width: 100%;
                    padding: 12px;

                    &:hover {
                        background: var(--primary, $primary);
                        background: var(--bg, $bg);
                    }

                    &:disabled {
                        color: var(--button-disabled, $button-disabled);
                        background-color: var(--input-bg, $input-bg);
                        cursor: default;
                    }
                }
            }
        }
    }
}