@import '../../../../shared/config/palette.scss';

.wrapper {
    background-color: var(--bg, $bg);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px 25px 25px 15px;

    &.loading {
        display: flex;
        height: 400px;
        align-items: center;
        justify-content: center;
    }

    .tooltip {
        background-color: var(--bg, $bg);
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        .value {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
        }

        .date {
            font-size: 10px;
            line-height: 10px;
        }
    }
}