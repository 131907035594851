@import '../../../shared/config/palette.scss';

.checkoutWireWrapper {
  padding: 70px 15px 89px;
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;
  min-height: 70vh;
}

.checkoutWire {
  .divider {
      border-bottom: 3px solid var(--primary, $primary);;
      margin: 33px 0;
      max-width: 220px;
  }

  .title {
      font-size: 18px;
      color: var(--menu-text, $menu-text);
      font-weight: 700;
      margin-bottom: 5px;
      margin-top: 23px;
  }

  .descr {
      font-size: 16px;
      font-weight: 500;
      color: var(--text, $text);
      margin-bottom: 20px;
      margin-left: 0;

      ul {
          padding-left: 20px;

          li {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
          }
      }

      .link {
          color: var(--primary, $primary);

          &:visited {
              color: var(--primary, $primary);
          }
      }
  }

  .fields {
      background-color: var(--light-green, $light-green);
      border: 1px solid var(--primary, $primary);
      padding: 52px 26px 26px;

      .downloadPdfBtn {
          background-color: inherit;
          padding: 16px 42px;
          color: $attention-red;
          font-weight: 700;
          font-size: 18px;
          outline: none;
          border: 1px solid $attention-red;
          transition: background-color .3s linear;

          &:hover {
              cursor: pointer;
              background-color: #f64a0013;
          }
      }
  }

  .fieldItem {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
      width: 100%;

      @media (max-width: $mobile-dashboard) {
          flex-direction: column;

      }

      .title {
          font-size: 16px;
          color: var(--menu-text, $menu-text);
          margin: 0;
          margin-right: 18px;
          font-weight: 400;
          text-transform: uppercase;
          width: 30%;
          text-align: right;
          margin-top: 5px;
        
        @media (max-width: $mobile-dashboard) {
              font-size: 14px;
              width: 100%;
              text-align: left;
          }
      }

      .info {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          width: 70%;
          display: block;
        
        @media (max-width: $mobile-dashboard) {
              font-size: 14px;
              width: 100%;
          }
      }
  }
}