@import '../../../../shared/config/palette.scss';

.wrapper {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--bg, $bg);

    .title {
        color: var(--menu-text, $menu-text);
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 16px;
        margin-top: 24px;
    }

    .table_container {
        .table {
            .head {
                background: var(--table-head-bg, $table-head-bg);

                th {
                    font-size: 12px;
                    line-height: 15px;
                    background: var(--table-head-bg, $table-head-bg);
                    padding: 19px 10px 16px 10px;
                    color: var(--table-head-text, $table-head-text);
                    .flexHead{
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .firstLoading{
                display: flex;
                padding: 150px;
                align-items: center;
                justify-content: center;
            }
            .emptyBlock {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: var(--menu-text, $menu-text);
                margin-top: 24px;
                margin-bottom: 24px;
            }
            tbody {
                position: relative;
                .loadingCell{
                    padding: 0;
                    border: none;

                    .loading {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg, $bg);
                        z-index: 1;
                    }
                }

                td {
                    font-style: normal;
                    font-size: 14px;
                    color: var(--text, $text);
                    line-height: 17px;
                    padding: 20px 10px;
                    min-height: 59px;

                    .image {
                        display: block;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    .pagination p,
    .pagination :global(.MuiSelect-select) {
        color: var(--text-secondary, $text-secondary);
    }
}

:global(.MuiTablePagination-root .MuiToolbar-root) {
    @media (max-width: $mobile) {
        font-size: 16px;
        p{
            font-size: 16px;
        }
        padding: 16px 0;
        flex-direction: column;
        width: 100%;

        :global(.MuiTablePagination-actions) {
            margin-left: 0;
        }

        :global(.MuiInputBase-root) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}