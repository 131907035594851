@import '../../../shared/config/palette.scss';

.title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.block {
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;

    .title {
        color: var(--menu-text, $menu-text);
        text-align: center;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .btn {
        background: var(--primary, $primary);
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: var(--bg, $bg);
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }
    }
}

.table {
    display: flex;
    flex-direction: column;
    background-color: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);

    nav {
        display: flex;
        height: 57px;
        border-bottom: 1px solid var(--button-disabled, $button-disabled);

        .tabButton {
            color: var(--text-secondary, $text-secondary);
            text-transform: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding: 16px;
            padding-top: 24px;
            border-bottom: 2px solid transparent;
            border-radius: 0;
            margin-bottom: -1px;
            transition: all .3s linear;

            // &:last-child{
            //     border-top-right-radius: 7px;
            // }
            &:hover {
                background-color: var(--hover-green, $hover-green);
            }

            &:disabled {
                color: var(--button-disabled, $button-disabled);
                cursor: not-allowed;
            }
        }

        :global(.Mui-selected) {
            border-color: var(--primary, $primary);
            color: var(--primary, $primary);
        }

        :global(.MuiTabs-scrollButtons.Mui-disabled) {
            opacity: 0.3;
        }
    }

    .body {
        .tabBody {
            min-height: 200px;
        }
    }
}

:global(.MuiTabs-scroller) {
    :global(.MuiTabs-indicator) {
        background-color: var(--primary, $primary);
    }
}