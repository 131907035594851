@import '../../../shared/config/palette.scss';

.checkout {
  .contractLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 300px 0;

    p {
      font-weight: 500;
      color: var(--text-secondary, $text-secondary);
      font-size: 15px;
      margin-bottom: 8px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding-top: 34px;

    @media (max-width: 1250px) {
      padding-top: 20px;
      flex-direction: column;
    }

    .contract {
      width: 100%;
      height: 100%;
      min-height: 650px;
      border: none;
    }
  }

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-dark, $primary-dark);
    padding: 20px 0;
    font-size: 13px;
    color: var(--bg, $bg);

    span {
      text-align: center;
    }

    .banner-icon {
      display: none;
    }

    @media(max-width: 600px) {
      flex-direction: column;

      .banner-icon {
        display: inline-block;
      }

      span {
        margin: 15px 10px;
      }
    }

    .link {
      color: var(--bg, $bg);
      cursor: pointer;
      background-color: transparent;
      outline: none;
      border: 2px solid var(--bg, $bg);
      border-radius: 4px;
      padding: 4px 15px;
      margin-left: 20px;
      margin-right: 10px;
      text-decoration: none;
      display: flex;
      align-items: center;

      @media(max-width: 600px) {
        margin-left: 0;
      }
    }
  }

  .title{
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--menu-text, $menu-text);
    margin-bottom: 5px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding: 16px 20px;
      background-color: var(--bg, $bg);
      border: 1px solid #b9c0c4;
      margin-bottom: 15px;
      &.completed{
        border: 1px solid $btn-primary;
      }
      &.error{
        font-weight: 500;
        border: 1px solid $attention-red;
      }

      .done{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: $primary;
        border-radius: 50%;
        margin-left: 15px;
        @media (max-width: $tablet) {
          margin-left: 0;
        }
      }
  
      .link {
        display: inline-block;
        margin-left: 16px;
        font-size: 16px;
        color: var(--bg, $bg);
        cursor: pointer;
        background-color: var(--primary, $primary);
        outline: none;
        border: none;
        border-radius: 4px;
        padding: 4px 15px;
        &:disabled{
          background-color: var(--button-disabled, $button-disabled);
          cursor: default;
        }
      }
    }
  }


  .verifyText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 24px;

    span {
      font-weight: 700;
      font-size: 14px;
    }

    .errorText {
      margin: 0;
      color: $attention-red;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
    }

    .link {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 700;
      font-size: 14px;
      color: $attention-red;
      text-transform: uppercase;
      margin-top: 10px;

      &:visited {
        color: $attention-red;
      }
    }
  }

  .left {
    width: 71%;

    @media (max-width: 1250px) {
      width: 100%;
    }

    .head {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      height: 28px;

      @media (max-width: 1140px) {
        text-align: center;
      }
    }

    .methods {
      display: flex;

      @media (max-width: 830px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      @media (max-width: 435px) {
        display: flex;
        flex-direction: column;
      }
    }

    .methodItem {
      display: flex;
      align-items: center;
      padding: 25px;
      margin-right: 15px;
      background: var(--bg, $bg);
      box-shadow: 0 4px 19px 0 rgba($color: #000000, $alpha: 0.1);
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 1050px) {
        padding: 18px 20px;
      }

      @media (max-width: 830px) {
        margin-right: 0;
        padding: 10px 20px 18px;
        width: initial;
      }

      @media (max-width: 400px) {
        margin-right: 0;
        padding: 10px 10px 18px;
      }

      .notificationsCheckbox {
        min-width: initial;
      }

      :global(.MuiCheckbox-root.Mui-checked) {
        color: var(--primary, $primary);
      }

      .label {
        margin-top: 2px;
        font-size: 18px;
        font-weight: 700;
        margin-left: -6px;
        text-transform: capitalize;
      }

      &.disabled {
        color: var(--button-disabled, $button-disabled);
        cursor: default;
      }
    }
  }

  .right {
    margin-top: 33px;
    width: 26%;

    @media (max-width: 1250px) {
      width: 100%;
    }

    .wrapper {
      padding: 10px 21px 23px;
      background-color: var(--bg, $bg);
      border: 1px solid #b9c0c4;
      margin-bottom: 15px;

      .title {
        color: var(--menu-text, $menu-text);
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .image {
        width: 100%;
        margin-bottom: 8px;

        img {
          width: 100%;
          min-height: 150px;
          object-fit: cover;
        }
      }

      .asset {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;

        .name {
          font-size: 14px;
          font-weight: 700;
        }

        .cost {
          font-size: 14px;
        }
      }

      .tokens {
        font-size: 14px;
        padding-bottom: 6px;
        border-bottom: 1px solid #b9c0c4;
      }

      .total {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;

        .title {
          color: var(--menu-text, $menu-text);
          font-size: 16px;
          font-weight: 700;
        }

        .amount {
          color: var(--primary, $primary);
          font-size: 18px;
          font-weight: 700;

          .currency {
            font-weight: 400;
          }
        }
      }

      .verifyText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 24px;

        span {
          font-weight: 700;
          font-size: 14px;
        }

        .link {
          font-weight: 700;
          font-size: 14px;
          color: $attention-red;
          text-transform: uppercase;
          margin-top: 10px;

          &:visited {
            color: $attention-red;
          }
        }
      }
    }
  }

  .cart {
    width: 71%;

    @media (max-width: 1250px) {
      width: 100%;
    }

    .head {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      height: 28px;

      @media (max-width: 1250px) {
        display: none;
      }

      .asset {
        width: 55%;
      }

      .quantity {
        width: 15%;
      }

      .price {
        width: 15%;
      }

      .subtotal {
        width: 15%;
      }
    }

    .card {
      display: flex;
      padding: 15px;
      background-color: var(--bg, $bg);
      border: 1px solid var(--hover-green, $hover-green);

      .img {
        width: 120px;
        height: 77px;
        overflow: hidden;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .label {
        display: none;

        @media (max-width: 1250px) {
          display: block;
        }
      }

      .asset {
        display: flex;
        width: 55%;

        @media (max-width: 1250px) {
          margin-bottom: 4px;
          width: 100%;
        }

        .title {
          color: var(--menu-text, $menu-text);
          font-size: 18px;
          font-weight: 700;

          @media (max-width: 1250px) {
            font-size: 16px;
          }
        }

        .tokens {
          font-size: 14px;
        }
      }

      .quantity {
        width: 15%;
      }

      .price {
        width: 15%;
      }

      .subtotal {
        width: 15%;
      }

      @media (max-width: 1250px) {
        flex-direction: column;

        .quantity,
        .price,
        .subtotal {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          .label {
            font-weight: 700;
          }
        }
      }
    }
  }

  .rightOverview {
    margin-top: 33px;
    width: 26%;

    @media (max-width: 1250px) {
      box-sizing: border-box;
      width: 100%;
      margin-top: 24px;
    }

    .wrapper {
      background-color: var(--bg, $bg);
      border: 1px solid var(--button-disabled, $button-disabled);
      margin-bottom: 15px;
    }

    .total {
      padding: 31px 26px 22px;

      @media (max-width: 768px) {
        padding: 31px 20px 22px;
      }

      .result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        .title {
          color: var(--menu-text, $menu-text);
          font-size: 16px;
          font-weight: 700;
        }

        .amount {
          font-size: 18px;
          color: var(--primary, $primary);
          font-weight: 700;

          .currency {
            font-weight: 400;
          }
        }
      }
    }
  }

  .next {
    border: none;
    outline: none;
    background-color: var(--primary, $primary);
    color: var(--bg, $bg);
    font-size: 20px;
    font-weight: 700;
    padding: 12px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
      padding: 13px;
    }

    &:hover {
      background-color: var(--primary-dark, $primary-dark);
      transition: all 0.2s ease-out;
    }

    &:disabled {
      background-color: var(--button-disabled, $button-disabled);
      cursor: initial;
    }

    img {
      margin-left: 8px;
    }
  }
}