@import '../../../../shared/config/palette';

.wrapper{
  padding: 16px 0 16px 16px;
  display: grid;
  grid-template-columns: 250px 1fr minmax(20px, 56px);
  align-items: center;
  outline: white;
  border-bottom: 1px solid var(--button-disabled, $button-disabled);
  background: var(--bg, $bg);
  transition: all 0.2s linear;
  &:hover {
    outline: 1px solid var(--primary, $primary);
    background: var(--light-green, $light-green);
    z-index: 10;
  }

  .titles{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    color: var(--text-dark, $text-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    word-break: break-all;
  }
  .subTitle{
    font-size: 12px;
    color: var(--text-secondary, $text-secondary);
    display: flex;
    align-items: center;
    line-height: 1.14;
    gap: 8px;
    .values{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      div{
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .label{
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .labelBlock{
    font-size: 12px;
    font-weight: 600;
    line-height: 12 / 16;
    padding: 8px;
    border-radius: 8px;
    color: var(--primary, $primary);
    background-color: var(--light-green, $light-green);
  }
  .warning{
    background-color: $attention-yellow;
    color: var(--bg, $bg);
  }
  .menuDots{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  @media (max-width: $tablet) {
    padding: 16px;
    grid-template-columns: 1fr minmax(20px, 56px);
    grid-template-rows: auto auto;
    grid-template-areas: 't t'
                          'l m';
    .titles{
      color: var(--text, $text);
      margin-bottom: 16px;
      grid-area: t;

    }
    .label{
      color: var(--text, $text);
      padding: 0;
      grid-area: l;
    }
    .menuDots{
      justify-content: flex-end;
      grid-area: m;
    }
  }
}