@import '../../../shared/config/palette.scss';

.title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.back {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-secondary, $text-secondary);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    transition: .3s;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 30px;
    outline: none;
    border: 0;
    img {
        margin-right: 8px;
        height: 13px;
        width: 13px;
    }

    &:hover {
        background-color: var(--input-bg, $input-bg);
    }
}

.block {
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);

    .formPart {
        margin-bottom: 16px;
    }

    .title {
        color: var(--menu-text, $menu-text);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .inputLabel {
        color: var(--text-secondary, $text-secondary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
        margin-top: 16px;
    }

    .inputBlock {
        width: 49%;

        .input {
            border-radius: 8px;
            margin-bottom: 0;
            margin-top: 0;
            width: 100%;

            :global(.MuiOutlinedInput-root) {
                background-color: var(--input-bg, $input-bg);
                border-radius: 8px;
                padding: 8px 16px;
                font-size: 14px;
                color: var(--menu-text, $menu-text);
            }

            :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
                border-color: var(--primary, $primary);
            }

            :global(.MuiAutocomplete-input.MuiOutlinedInput-input) {
                padding: 0;
            }

            p {
                margin: 0;
                color: $attention-red;
                font-size: 12px;
                line-height: 16px;
                padding-top: 8px;
            }

            fieldset {
                border: 1px solid var(--input-bg, $input-bg);
                border-radius: 8px;
            }

            :global(.Mui-error) fieldset {
                border-color: $attention-red;
            }

            :global(label.Mui-focused) {
                color: var(--primary, $primary);
            }

            input {
                height: 24px;
                padding: 0;
                font-size: 14px;
                color: var(--menu-text, $menu-text);
            }

        }
    }

    .select {
        width: 100%;
        border-radius: 8px;
        margin-top: 0px;
        margin-bottom: 0;
        background-color: var(--input-bg, $input-bg);
        border-radius: 8px;
        color: var(--menu-text, $menu-text);

        fieldset {
            border: 1px solid var(--input-bg, $input-bg);
            border-radius: 8px;
        }
    }

    :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
        border-color: var(--primary, $primary);
    }

    .checkboxes {
        display: flex;
        flex-direction: column;

        .checkbox {
            width: fit-content;
            
            &:checked {
                color: var(--primary, $primary);
            }
            
            :global(.MuiCheckbox-colorPrimary.Mui-checked) {
                color: var(--primary, $primary);
            }
        }
    }

    .checkbox :global(.MuiFormControlLabel-label) {
        font-size: 14px;
        letter-spacing: 0em;
    }

    .inputsBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.btns {
    padding: 24px 0px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        background: var(--primary, $primary);
        color: var(--bg, $bg);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }
        &:disabled {
            background-color: transparent;
            color: var(--button-disabled, $button-disabled);
            cursor: default;
        }
    }

    .small{
        padding: 8px 16px;
        margin: 0 5px;
    }

    .btnEmpty {
        background: transparent;
        color: var(--primary, $primary);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
            background: var(--hover-green, $hover-green);
        }
    }
}
.small{
    padding: 0;
}

.errors {
    display: flex;
    flex-direction: column;
}

.errorText {
    margin: 0;
    color: $attention-red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}

.validationText {
    margin: 0;
    color: $attention-red;
    font-size: 12px;
    line-height: 14px;
}

.dialog {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    min-width: 600px;
    box-sizing: border-box;

    @media (max-width:$mobile) {
        min-width: auto;
    }

    .title {
        color: var(--menu-text, $menu-text);
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        margin-bottom: 0;
    }

    .text {
        text-align: center;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .actions {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
    }

    .submitButton {
        box-sizing: border-box;
        text-decoration: none;
        text-align: center;
        outline: none;
        border: none;
        width: 100%;
        margin-top: 24px;
        padding: 16px 32px;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        margin: 0 5px;
        transition: background-color .3s linear;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-dark, $primary-dark);
        }
    }
    .outlined{
        margin-top: 16px;
        color: var(--primary, $primary);
        background-color: transparent;
        &:hover{
            background-color: var(--hover-green, $hover-green);
        }
    }
}