@import '../../../shared/config/palette.scss';

.title {
    color: var(--menu-text, $menu-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.investReady{
    width: 100%;
    min-height: 950px;
    overflow: hidden;
    border: none;
}

.hide{
    display: none;
}

.back {
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-secondary, $text-secondary);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    transition: .3s;
    border-radius: 5px;
    margin-bottom: 30px;

    img {
        margin-right: 8px;
        height: 13px;
        width: 13px;
    }

    &:hover {
        background-color: var(--input-bg, $input-bg);
    }
}

.block {
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);

    .formPart {
        margin-bottom: 16px;
    }

    .title {
        color: var(--menu-text, $menu-text);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }
    .skip {
        background: none;
        cursor: pointer;
        border: none;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: var(--primary, $primary);
    }

    .fields {
        margin-bottom: 24px;

        .select {
            display: flex;
            align-items: stretch;

            @media(max-width: $tablet) {
                flex-direction: column;
            }

            .item {
                cursor: pointer;
                background: var(--bg, $bg);
                width: 100%;
                border: 1px solid var(--button-disabled, $button-disabled);
                padding: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 8px;
                margin-right: 16px;
                transition: background-color .3s linear;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    border-color: var(--primary, $primary);
                    background-color: var(--hover-green, $hover-green);
                    z-index: 1;
                }

                &:hover {
                    background-color: var(--hover-green, $hover-green);
                }
                @media(max-width: $tablet) {
                    margin-bottom: 16px;
                }

                header {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: center;
                }

                p {
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 8px;
                    color: var(--text-secondary, $text-secondary);
                    text-align: center;
                }

                :global(.MuiCheckbox-colorPrimary.Mui-checked) {
                    color: var(--primary, $primary);
                }
            }
        }
    }

    .checkbox :global(.MuiFormControlLabel-label) {
        font-size: 14px;
        letter-spacing: 0em;
    }

    .inputsBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.btns {
    padding: 24px 0px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        background: var(--primary, $primary);
        color: var(--bg, $bg);
        text-decoration: none;
        text-transform: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }

        &:disabled {
            background-color: transparent;
            color: var(--button-disabled, $button-disabled);
        }
    }

    .btnEmpty {
        background: transparent;
        text-transform: none;
        color: var(--primary, $primary);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
            background: var(--hover-green, $hover-green);
        }
    }
}

.errors {
    display: flex;
    flex-direction: column;
}

.errorText {
    margin: 0;
    color: $attention-red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}

.dialog {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    min-width: 600px;
    box-sizing: border-box;

    @media (max-width:$mobile) {
        min-width: auto;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        margin-bottom: 0;
    }

    .text {
        text-align: center;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .actions {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
    }

    .submitButton {
        box-sizing: border-box;
        text-decoration: none;
        text-transform: none;
        text-align: center;
        outline: none;
        border: none;
        width: 100%;
        margin-top: 24px;
        padding: 16px 32px;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        margin: 0 5px;
        transition: background-color .3s linear;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-dark, $primary-dark);
        }
    }

    .outlined {
        margin-top: 16px;
        color: var(--primary, $primary);
        background-color: transparent;

        &:hover {
            background-color: var(--hover-green, $hover-green);
        }
    }
}

.wrapper {
    padding-top: 84px;
    background-color: var(--primary, $primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .form {
        min-height: unset;
        max-width: calc(100% - 32px);
        margin-bottom: 50px;

        .page_header {
            margin-top: 25px;
            margin-bottom: 18px;
            font-weight: bold;
            font-size: 30px;
            color: var(--bg, $bg);

            @media(max-width: 768px) {
                font-size: 24px;
            }
        }


    }
}