@import '../../../../shared/config/palette.scss';

.wrapper {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--bg, $bg);

    .table_container {

        .table {
            .head {
                background: var(--table-head-bg, $table-head-bg);

                th {
                    font-size: 12px;
                    line-height: 15px;
                    background: var(--table-head-bg, $table-head-bg);
                    padding: 19px 10px 16px 10px;
                    color: var(--table-head-text, $table-head-text);
                    .flexHead{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .firstLoading{
                display: flex;
                padding: 150px;
                align-items: center;
                justify-content: center;
            }
            tbody {
                position: relative;

                .loading {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparentize($bg, 0.5);
                    z-index: 1;
                }

                td {
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    padding: 20px 10px;
                    min-height: 59px;

                    .image {
                        display: block;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}