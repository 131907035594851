@import '../../../shared/config/palette.scss';

.assetFullAccess {
    background-color: var(--light-green, $light-green);
    border: 1px solid var(--primary, $primary);
    padding: 44px 50px 58px;
    margin-bottom: 25px;
    text-align: center;
    @media (max-width: $mobile-dashboard) {
      padding: 20px 10px;
    }
    .title {
      font-size: 28px;
      color: var(--primary, $primary);
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 20px;
      @media (max-width: $mobile-dashboard) {
        font-size: 24px;
      }
    }
    .descr {
      font-size: 18px;
      color: var(--text, $text);
      line-height: 28px;
      margin-bottom: 25px;
      @media (max-width: $mobile-dashboard) {
        font-size: 16px;
      }
    }
  
    .form {
      display: flex;
      align-items: center;
      flex-direction: column;
  
      .email {
        box-sizing: border-box;
        border-radius: 0;
        padding: 15px 18px 15px 10px;
        height: 54px;
        border: 1px solid #dbe0ea;
        outline: none;
        font-size: 18px;
        color: var(--text-secondary, $text-secondary);
        width: 100%;
        text-align: center;
        margin-bottom: 17px;
      }
  
      .enter {
        width: 100%;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-width: 236px;
        padding: 0 16px;
        height: 54px;
        color: var(--bg, $bg);
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        background-color: var(--primary, $primary);
        transition: all 0.3s ease-in-out;
        width: 100%;
  
        &:hover {
          background-color: var(--primary-dark, $primary-dark);
          transition: all 0.2s ease-in-out;
        }
      }
  
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
  
        .email {
          margin-bottom: 12px;
          max-width: 335px;
          width: 100%;
          min-width: initial;
        }
  
        .enter {
          max-width: 335px;
          width: 100%;
        }
      }
    }
  }
  