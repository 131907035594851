@import '../../../shared/config/palette.scss';

.checkoutStages {
  background-color: var(--light-green, $light-green);
  padding: 56px 0 24px;
  border: 1px solid var(--primary, $primary);
  display: flex;
  align-items: center;
  justify-content: center;

  .line {
    height: 2px;
    background-color: var(--primary, $primary);
    position: relative;
    width: 440px;

    @media (max-width: $tablet) {
      width: 340px;
    }

    @media (max-width: 800px) {
      width: 220px;
    }

  }

  .before {
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s linear;
    display: inline;
  }

  .first {
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s linear;
    left: 30%;

    &:hover {
      cursor: pointer;
      background-color: var(--light-green, $light-green);
    }
  }

  .third {
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .second {
    left: 65%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .notClickable {
    cursor: default;

    &:hover {
      cursor: default;
    }
  }

  .before,
  .first,
  .second,
  .third {
    position: absolute;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background-color: var(--bg, $bg);
    border: 1px solid var(--primary, $primary);

    &.active {
      background-color: var(--primary, $primary);

      .label {
        font-weight: 700;
      }
    }
  }

  .label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    font-size: 14px;

    @media (max-width: 930px) {
      font-size: 12px;
    }
  }
}