@import "../../shared/config/palette";

.wrapper{
  .tableHead{
    background-color: var(--input-bg, $input-bg);
    border-radius: 0;
    tr{
      th{
        padding: 16px 8px;
      }
      th:first-child{
        padding-left: 16px;
      }
      th:last-child{
        padding-right: 16px;
      }
    }
  }
  .headCell{
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 133%;
    color: var(--text-secondary, $text-secondary);
  }
  .tableRow{
    transition: all 0.2s linear;
    vertical-align: top;
    &:hover {
      outline: 1px solid var(--primary, $primary);
      background: var(--light-green, $light-green);
    }
    td{
      padding: 16px 8px;
    }
    td:first-child{
      padding-left: 16px;
    }
    td:last-child{
      padding-right: 16px;
    }
  }
  .pagination p,
  .pagination :global(.MuiSelect-select) {
    color: var(--text-secondary, $text-secondary);
  }
  .emptyBlock {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary, $primary);
  }
}