@import '../../../shared/config/palette.scss';

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--menu-text, $menu-text);
}

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 40px;
}


.dialog {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    min-width: 600px;
    box-sizing: border-box;

    @media (max-width:$mobile) {
        min-width: auto;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        color: var(--menu-text, $menu-text);
        line-height: 32px;
        text-align: center;
        margin-bottom: 0;
    }

    .text {
        text-align: center;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .actions {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
    }

    .submitButton {
        box-sizing: border-box;
        text-decoration: none;
        text-transform: none;
        text-align: center;
        outline: none;
        border: none;
        width: 100%;
        margin-top: 24px;
        padding: 16px 32px;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        margin: 0 5px;
        transition: background-color .3s linear;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-dark, $primary-dark);
        }
    }

    .outlined {
        margin-top: 16px;
        color: var(--primary, $primary);
        background-color: transparent;

        &:hover {
            background-color: var(--hover-green, $hover-green);
        }
    }
}


.topPanel {
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;

    @media (max-width:$tablet-asset) {
        padding-top: 16px;
        width: 100%;
        margin-right: 0;
    }

    @media (max-width: $tablet) {
        padding: 16px;
    }

    :global(.MuiInputBase-input) {
        padding: 8px;
        color: var(--text-secondary, $text-secondary);
    }

    :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
        border-color: var(--primary-dark, $primary-dark);
    }

    .search {
        @media (max-width: $tablet) {
            width: 100%;
        }
    }

    .searchBtn {
        background: var(--primary, $primary);
        color: var(--bg, $bg);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        outline: none;
        border: none;
        cursor: pointer;
        transition: background-color .3s linear;

        &:hover {
            background-color: var(--primary-dark, $primary-dark);
        }
    }
}

.table {
    @media (max-width:900px) {
        border-top: 1px solid var(--button-disabled, $button-disabled);
    }

    .titleRow {
        display: grid;
        grid-template-columns: 236px minmax(110px, 205px) repeat(3, minmax(70px, 1fr)) 40px;
        grid-column-gap: 0.5rem;
        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);

        @media (max-width:$tablet) {
            grid-template-columns: 1fr;
            justify-items: center;
        }

        .orderButton {
            padding: 5px;
        }

        .columnLeftTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                width: fit-content;
                flex: 0 1 auto;
            }
        }

        .rightsTitles {
            display: flex;
            width: calc(100% - 250px);
            justify-content: space-between;
        }

        .columnRightTitleStatus {
            padding: 16px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                &.noSort {
                    display: none;
                }
            }
        }
    }

    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .pagination {
        color: var(--text-secondary, $text-secondary);
    }
}