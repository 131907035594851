@import '../../shared/config/palette.scss';
.dropDownMenu {
  @media (max-width: $mobile-dashboard) {
    display: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .topmenu > li {
    position: relative;
  }
  .topmenu > li > .dropController {
    color: var(--menu-text, $menu-text);
    position: relative;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 15px);
      height: calc(100% + 15px);
      left: -5px;
      top: 0;
    }
    img{
      height: 32px;
      width: 32px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .profileInfo{
      display: flex;
      flex-direction: column;
      .name{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: var(--text-dark, $text-dark);
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .role{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--text-grey, $text-grey);
        text-transform: capitalize;
      }
    }
  }
  .submenu {
    padding: 8px 0;
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    position: absolute;
    right: -13px;
    top: calc(100% + 15px);
    z-index: 5;
    min-width: 232px;
    opacity: 0;
    transform: scaleY(0);
    transition: opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 91px 0px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }

  .submenu a {
    float: left;
    display: flex;
    align-items: center;
    color: var(--menu-text, $menu-text);
    padding: 8px 16px;
    text-decoration: none;
    img{
        margin-right: 8px;
    }
    &:hover{
        background-color: var(--hover-green, $hover-green);
    }
  }
  // .submenu a.logout{
  //   border-top: 1px solid var(--button-disabled, $button-disabled);
  // }
  .submenu a.disable{
    color: var(--button-disabled, $button-disabled);
    pointer-events: none;
  }

  .topmenu > li:hover .submenu {
    opacity: 1;
    transform: scaleY(1);
  }
}
