@import '../../../shared/config/palette.scss';

.emptyBlock {
    background: var(--bg, $bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;
    margin-bottom: 20px;
    border-radius: 8px;

    .title {
        color: var(--menu-text, $menu-text);
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 24px;
    }

    .text {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .btn {
        background: var(--primary, $primary);
        text-decoration: none;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: var(--bg, $bg);
        outline: none;
        border: none;
        border-radius: 8px;
        width: max-content;
        transition: all .3s linear;

        &:hover {
            cursor: pointer;
            background: var(--primary-dark, $primary-dark);
        }
    }

    .assets {
        display: flex;
        flex-direction: column;
        width: 100%;

        .assetRow {
            display: flex;
            border-bottom: 1px solid var(--button-disabled, $button-disabled);
            padding: 16px 8px;
            &:first-child{
                border-top: 1px solid var(--button-disabled, $button-disabled);
            }
            .assetName{
                margin-right: 15px;
                font-weight: 600;
            }
            .number{
                margin-right: 10px;
            }
        }
    }
}