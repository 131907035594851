@import '../../../shared/config/palette';

.wrapper {
  width: 100%;

  .label {
    font-size: 1rem;
    color: $text;
    margin-bottom: 8px;
  }

  .input {
    border-radius: 8px;
    margin-bottom: 0;
    margin-top: 0;
     
    width: 100%;

    :global(.MuiOutlinedInput-root) {
      background-color: var(--input-bg, $input-bg);
      border-radius: 8px;
      padding: 8px 16px;
       
      font-size: 14px;
      color: var(--menu-text, $menu-text);
    }

    :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
      border-color: var(--primary, $primary);
    }

    :global(.MuiAutocomplete-input.MuiOutlinedInput-input) {
      padding: 0;
    }

    p {
       
      margin: 0;
      color: $attention-red;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
    }

    fieldset {
      border: 1px solid var(--input-bg, $input-bg);
      border-radius: 8px;
    }

    :global(.Mui-error) fieldset {
      border-color: $attention-red;
    }

    :global(label.Mui-focused) {
      color: var(--primary, $primary);
    }

    input {
      height: 24px;
      padding: 0;
       
      font-size: 14px;
      color: var(--menu-text, $menu-text);
    }

  }

  .errorText {
    font-size: 12px;
    color: $attention-red;
    margin: 8px 0 0 0;
     
  }

  :global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: var(--primary, $primary);
  }
}