@import '../../shared/config/palette.scss';

.powered {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    max-width: calc(100% - 24px);
    width: max-content;
    text-align: center;
    color: var(--text-dark, $text-dark);

    a {
        color: var(--primary, $primary);
        font-weight: 700;
        text-decoration: none;

        span {
            color: var(--text-dark, $text-dark);
        }
    }

    .terms{
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
    }
}