@import '../../../../shared/config/palette';

.mobileMenu {
    box-sizing: border-box;
    position: fixed;
    top: 56px;
    left: -150%;
    display: flex;
    flex-direction: column;
    z-index: 998;
    height: 100vh;
    width: 100vw;
    background: var(--bg, $bg);
    align-items: flex-start;
    display: none;
    overflow-y: auto;
    transition: all .3s ease-in-out;
    padding-top: 22px;
    padding-bottom: 56px;

    @media (max-width: $mobile-dashboard) {
        display: inline-block;
    }

    &.open {
        left: 0;
        transition: all .3s ease-in-out;
    }

    .profileBlock {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--button-disabled, $button-disabled);

        img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin-right: 16px;
        }

        .profileInfo {
            display: flex;
            flex-direction: column;

            .name {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                color: var(--text-dark, $text-dark);
                word-wrap: break-all;
            }

            .role {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--text-grey, $text-grey);
                text-transform: capitalize;
            }
        }
    }

    .settingsMenu {
        border-top: 1px solid var(--button-disabled, $button-disabled);
        border-bottom: 1px solid var(--button-disabled, $button-disabled);
    }

    .link {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: none;
        padding-bottom: 16px;
        padding-top: 16px;
        padding-left: 17px;
        color: var(--menu-text, $menu-text);
        transition: all .3s linear;

        .img {
            margin-right: 17px;
            fill: var(--text-secondary, $text-secondary);
            transition: all .3s linear;
        }

        img{
            margin-right: 17px;
        }

        &:visited {
            color: var(--menu-text, $menu-text);
        }

        &:hover {
            color: var(--primary, $primary);
            background: var(--input-bg, $input-bg);

            .img {
                filter: grayscale(0);
                fill: var(--primary, $primary);
            }
        }

        &.active {
            color: var(--primary, $primary);
            background: var(--hover-green, $hover-green);

            .img {
                fill: var(--primary, $primary);
            }
        }
    }

    .link.disabled {
        color: var(--button-disabled, $button-disabled);
        pointer-events: none;
    }
}