@import '../../../shared/config/palette.scss';

.investmentDetails {
    border-radius: 8px;
    background-color: var(--light-green, $light-green);
    border: 1px solid var(--primary, $primary);
    padding: 25px 32px;
    margin-bottom: 30px;

    .title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 16px;
        color: var(--primary, $primary);
    }

    .fieldItem {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid var(--primary, $primary);

        &:last-of-type {
            border-bottom: none;
        }

        .field {
            font-size: 18px;
        }

        .value {
            font-size: 18px;
            font-weight: 700;
        }
    }

    @media(max-width: $mobile-dashboard) {
        padding: 20px 10px;

        .title {
            font-size: 24px;
        }

        .fieldItem {

            .field,
            .value {
                font-size: 16px;
            }
        }
    }
}