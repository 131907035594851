@import '../../../../shared/config/palette.scss';

.wrapper {
  .submitButton {
    outline: none;
    border: none;
    width: auto;
     
    padding: 12px 24px;
    background-color: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: .3s background-color linear;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-dark, $primary-dark);
    }

    &:disabled {
      color: var(--button-disabled, $button-disabled);
      background: var(--input-bg, $input-bg);
    }

    @media (max-width:900px) {
      justify-content: center;
      width: 100%;
      margin-top: 16px;
    }
  }
}

.dialog {
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 560px;
  box-sizing: border-box;

  @media (max-width:$mobile) {
    min-width: auto;
  }

  .closeIcon {
    position: absolute;
    top: 29px;
    right: 29px;

    @media (max-width:$mobile) {
      position: static;
      margin-top: 10px;
    }
  }

  .logoutIcon {
    position: absolute;
    top: 23px;
    right: 25px;
    border: 1px solid var(--text-secondary, $text-secondary);
    border-radius: 8px;

    img{
      margin-right: 8px;
    }
    color: var(--text-secondary, $text-secondary);

    @media (max-width:$mobile) {
      position: static;
      margin-top: 10px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--menu-text, $menu-text);
    text-align: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--button-disabled, $button-disabled);
  }

  .smallTitle {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .contracts {
    margin-top: 16px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--button-disabled, $button-disabled);

    .alert{
      display: flex;
      flex-direction: column;
      padding: 5px;
      .contractData{
        margin-bottom: 16px;
      }

      .submitButton {
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        transition: background-color .3s linear;

        &:hover {
          cursor: pointer;
          background-color: var(--primary-dark, $primary-dark);
        }

        &:disabled {
          color: var(--button-disabled, $button-disabled);
          background-color: var(--input-bg, $input-bg);
          cursor: default;
        }
      }
    }

    .contract {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      .submitButton {
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        margin: 0 5px;
        transition: background-color .3s linear;

        &:hover {
          cursor: pointer;
          background-color: var(--primary-dark, $primary-dark);
        }

        &:disabled {
          color: var(--button-disabled, $button-disabled);
          background-color: var(--input-bg, $input-bg);
          cursor: default;
        }
      }
    }
  }

  :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
    border-color: var(--primary-dark, $primary-dark);
  }
}

.errorText {
  margin: 0;
  color: $attention-red;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
}