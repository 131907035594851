@import "../../../shared/config/palette";

.defaultButton {
  outline: none;
  border: none;
  width: auto;
  padding: 12px 24px;
  background: var(--input-bg, $input-bg);
  color: var(--menu-text, $menu-text);
  border-radius: 8px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: none;
  display: flex;
  align-items: center;
  transition: background-color .3s linear;
  margin: 0 5px;

  &:hover {
      cursor: pointer;
      background-color: var(--button-disabled, $button-disabled);
  }

  &:disabled {
      color: var(--button-disabled, $button-disabled);
      background: var(--input-bg, $input-bg);
      cursor: not-allowed;

      img {
          filter: opacity(0.5);
      }
  }

  img {
      margin-right: 9px;
  }

  @media (max-width:$mobile-dashboard) {
      justify-content: center;
      width: 98%;
      margin-top: 16px;
  }
}

.mainColor{
    color: var(--bg, $bg);
    background: var(--primary, $primary);
    &:hover {
        background-color: var(--primary-dark, $primary-dark);
    }
}