@import "../../../../shared/config/palette";


.filterPanel {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width:1330px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .left {
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;

    @media (max-width:1330px) {
      padding-top: 16px;
      border-top: 1px solid var(--button-disabled, $button-disabled);
      width: 100%;
      margin-right: 0;
    }

    @media (max-width:900px) {
      padding: 16px;
    }

    .text {
      color: var(--text-secondary, $text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }

    margin-right: 10px;

    .select {
      border-radius: 8px;
      margin-bottom: 0;
       
      background-color: var(--input-bg, $input-bg);
      border-radius: 8px;
      color: var(--menu-text, $menu-text);
      width: 280px;
      max-width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      p {
         
        margin: 0;
        color: $attention-red;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
      }

      fieldset {
        border: 1px solid var(--input-bg, $input-bg);
        border-radius: 8px;
      }
    }

    :global(.MuiInputBase-input) {
      padding: 8px 16px;
    }
  }

  :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
    border-color: var(--primary-dark, $primary-dark);
  }

  .right {
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    align-items: flex-start;

    @media (max-width:900px) {
      flex-direction: column;
      width: 100%;
    }
  }
}