@import '../../../shared/config/palette.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	.listHighlights {
		display: flex;
		flex-direction: column;
		margin-top: 8px;

		.highlight {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			.left {
				width: calc(100% - 50px);
			}
		}
	}
}

.addButton {
	width: max-content;
	color: var(--primary, $primary);
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	padding: 8px;
	margin-top: 8px;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	border: none;
	background: transparent;
	transition: background-color .3s linear;

	&:hover {
		background-color: var(--hover-green, $hover-green);
	}
}