@import '../../../shared/config/palette.scss';

.wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 32px;
    width: 100%;
    @media (max-width: $tablet) {
        flex-direction: column;
        align-items: normal;
    }

    .block {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: var(--bg, $bg);
        border-radius: 8px;
        padding: 16px;
        box-sizing: border-box;

        .title {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: var(--text-secondary, $text-secondary);
        }

        .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            line-height: 24px;
            color: var(--text-dark, $text-dark);

            @media (max-width: $mobile) {
                flex-direction: column;
                align-items: initial;
            }

            .text {
                font-size: 24px;
            }

            .wallet {
                font-size: 16px;
                word-break: break-all;
            }

            .buttons {
                display: flex;
            }
        }

        &.left {
            width: 37%;
            margin-right: 24px;
            @media (max-width: $tablet) {
                margin-right: 0;
                margin-bottom: 24px;
                width: 100%;
            }
        }

        &.right {
            width: 60%;
            @media (max-width: $tablet) {
                width: 100%;
            }
        }
    }
}