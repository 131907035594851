@import '../../../shared/config/palette.scss';

.wrapper {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--bg, $bg);

    .emptyBlock {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--primary, $primary);
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .topPanel {
        .filterPanel {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
    
            @media (max-width:1330px) {
                flex-direction: column-reverse;
                align-items: flex-start;
            }
    
            .left {
                padding: 16px 0px 16px 16px;
                box-sizing: border-box;
    
                @media (max-width:1330px) {
                    padding-top: 16px;
                    border-top: 1px solid var(--button-disabled, $button-disabled);
                    width: 100%;
                    margin-right: 0;
                }
    
                @media (max-width:900px) {
                    padding: 16px;
                }
    
                .text {
                    color: var(--text-secondary, $text-secondary);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 8px;
                }
    
                margin-right: 10px;
    
                .select {
                    border-radius: 8px;
                    margin-bottom: 0;
                     
                    background-color: var(--input-bg, $input-bg);
                    border-radius: 8px;
                    color: var(--menu-text, $menu-text);
                    width: 280px;
                    max-width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
    
                    p {
                         
                        margin: 0;
                        color: $attention-red;
                        font-size: 12px;
                        line-height: 16px;
                        padding-top: 8px;
                    }
    
                    fieldset {
                        border: 1px solid var(--input-bg, $input-bg);
                        border-radius: 8px;
                    }
                }
    
                :global(.MuiInputBase-input) {
                    padding: 8px 16px;
                }
            }
    
            :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
                border-color: var(--primary-dark, $primary-dark);
            }
    
            .right {
                box-sizing: border-box;
                padding: 16px;
                display: flex;
                align-items: flex-start;
    
                @media (max-width:900px) {
                    flex-direction: column;
                    width: 100%;
                }
    
                .button {
                    outline: none;
                    border: none;
                    width: auto;
                     
                    padding: 12px 24px;
                    background: var(--input-bg, $input-bg);
                    color: var(--menu-text, $menu-text);
                    border-radius: 8px;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: none;
                    margin: 0 5px;
                    display: flex;
                    align-items: center;
    
                    &:hover {
                        cursor: pointer;
                        background: var(--button-disabled, $button-disabled);
                    }
    
                    &:disabled {
                        color: var(--button-disabled, $button-disabled);
                        background: var(--input-bg, $input-bg);
                        cursor: not-allowed;
    
                        img {
                            filter: opacity(0.5);
                        }
                    }
    
                    img {
                        margin-right: 9px;
                    }
    
                    @media (max-width:900px) {
                        justify-content: center;
                        width: 100%;
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    .table_container {
         

        .table {
            .head {
                background: var(--table-head-bg, $table-head-bg);

                th {
                     
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    background: var(--input-bg, $input-bg);
                    padding: 16px 20px;
                    color: var(--text-secondary, $text-secondary);

                    .flexHead {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .left {
                        justify-content: flex-start;
                    }
                }
            }

            .firstLoading {
                display: flex;
                padding: 150px;
                align-items: center;
                justify-content: center;
            }

            tbody {
                position: relative;

                .loadingCell {
                    padding: 0;
                    border: none;

                    .loading {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg, $bg);
                        z-index: 1;
                    }
                }

                tr{
                    transition: background-color .3s linear;
                }

                tr:hover{
                    outline: 1px solid var(--primary, $primary);
                    background-color: var(--light-green, $light-green);
                }

                td {
                     
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--text, $text);
                    padding: 16px 20px;
                    min-height: 59px;
                    max-width: 202px;

                    .name {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                        color: var(--text-dark, $text-dark);
                        margin-bottom: 8px;
                        word-break: break-all;
                    }

                    .email {
                        min-width: 202px;
                        display: flex;
                        word-break: break-all;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        color: var(--text-secondary, $text-secondary);

                        button {
                            background-color: transparent;
                            border-radius: 8px;
                            border: none;
                            margin-left: 8px;
                            cursor: pointer;
                            transition: background-color .3s linear;

                            &:active {
                                background-color: var(--primary, $primary);
                            }
                        }
                    }

                    .image {
                        display: block;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .pagination p,
    .pagination :global(.MuiSelect-select) {
         
        color: var(--text-secondary, $text-secondary);
    }
}

:global(.MuiTablePagination-root .MuiToolbar-root) {
     
    @media (max-width: $mobile) {
        font-size: 16px;
        p{
            font-size: 16px;
        }
        padding: 16px 0;
        flex-direction: column;
        width: 100%;

        :global(.MuiTablePagination-actions) {
            margin-left: 0;
        }

        :global(.MuiInputBase-root) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}