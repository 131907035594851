@import '../../../shared/config/palette.scss';

.investorPerks {
    border-radius: 8px;
    background-color: var(--light-green, $light-green);
    border: 1px solid var(--primary, $primary);
    margin-bottom: 30px;
    position: relative;
    margin-top: 80px;
  
    .top {
      padding: 74px 32px 34px;
    }
    .icon {
      position: absolute;
      left: 50%;
      top: -62px;
      transform: translateX(-50%);
      fill: var(--primary, $primary);
    }
  
    .title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 16px;
      color: var(--primary, $primary);
      text-align: center;
  
      @media (max-width: $mobile-dashboard) {
        font-size: 24px;
      }
    }
  
    .descr {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
      font-weight: 700;
      text-align: center;
  
      @media (max-width: $mobile-dashboard) {
        font-size: 16px;
      }
    }
  
    .fieldItem {
      display: flex;
      flex-direction: column;
      padding: 22px 32px;
  
      &:nth-child(even) {
        background-color: var(--light-green, $light-green);
      }
      &:nth-child(odd) {
        background-color: var(--hover-green, $hover-green);
      }
  
      &:last-of-type {
        border-bottom: none;
        border-radius: 0px 0px 8px 8px;
      }
  
      .invest {
        font-size: 18px;
        color: var(--primary, $primary);
        line-height: 44px;
        font-weight: 700;
      }
  
    }
  }
  