@import '../../shared/config/palette.scss';

.dropDownMenu {
  .mobileNotifications {
    display: none;

    @media (max-width: $mobile-dashboard) {
      display: inline-block;
    }
  }

  .notifications {
    z-index: 5;
    position: relative;
    align-self: flex-end;
    margin-right: 24px;
    background: none;
    border: none;
    cursor: pointer;

    .notificationBlock {
      width: 16px;
      height: 16px;
      min-width: max-content;
      color: var(--bg, $bg);
      background: $attention-red;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -2px;
      display: none;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
    }

    .show {
      display: inline-block;
    }
  }

  .topMenu {
    position: relative;

    @media (max-width: $mobile-dashboard) {
      display: none;
    }
  }

  .backdrop {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    background: none;
    height: 100%;
    width: 100vw;
  }

  .subMenu {
    padding: 8px 0;
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    position: absolute;
    right: 6px;
    top: calc(100% + 15px);
    z-index: 5;
    min-width: 550px;
    opacity: 0;
    transform: scaleY(0);
    transition: opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 91px 0px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;

    @media (max-width: $tablet) {
      min-width: 50vw;
    }

    .title {
      font-weight: 600;
      padding: 8px 16px;
    }

    .list {
      display: flex;
      flex-direction: column;

      .notification {
        display: flex;
        flex-direction: column;
        color: var(--menu-text, $menu-text);
        padding: 8px 16px 8px 32px;
        text-decoration: none;
        border-top: 1px solid var(--button-disabled, $button-disabled);
        transition: background-color .1s linear;
        position: relative;
        font-size: 14px;
        cursor: default;

        img {
          margin-right: 8px;
        }

        &:hover {
          background-color: var(--hover-green, $hover-green);
        }

        &::after {
          content: '';
          width: 8px;
          height: 8px;
          color: var(--bg, $bg);
          background: var(--primary, $primary);
          border-radius: 50%;
          position: absolute;
          left: 16px;
          top: 13px;
          cursor: pointer;
        }

        .time {
          margin-top: 8px;
          display: flex;
          color: var(--text-secondary, $text-secondary);
        }

        .buttons {
          display: flex;
          gap: 5px;
          margin-top: 8px;

          @media (max-width: $mobile-dashboard) {
            max-width: 100%;
            flex-direction: column;
          }
        }
      }
    }
  }

  .link {
    font-size: 14px;
    padding: 12px 16px 8px;
    color: var(--primary, $primary);
    text-align: center;
    border-top: 1px solid var(--button-disabled, $button-disabled);
  }

  .open {
    opacity: 1;
    transform: scaleY(1);
  }
}