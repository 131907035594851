@import '../../../shared/config/palette.scss';
.wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;

    .list {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, minmax(auto, 546px));
        column-gap: 28px;
        row-gap: 16px;
    }
}

.noAssets {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--primary, $primary);
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    padding: 300px 0;
}