@import "../../../../shared/config/palette";

.wrapper{
  .cancelActionBtn{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--menu-text, $menu-text);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    padding: 16px 32px;
    transition: all .3s linear;
    &:hover {
      background-color: var(--button-disabled, $button-disabled);
    }
  }

  .agreeActionBtn{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--bg, $bg);
    border-radius: 8px;
    transition: all .3s linear;
    background-color: var(--primary, $primary);
    padding: 16px 32px;
    &:hover {
      background-color: var(--primary-dark, $primary-dark);
    }
  }

  .title{
    color: var(--text-dark, $text-dark);
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }



  .content{
    color: var(--text-dark, $text-dark);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
  }
}

