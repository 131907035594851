.copyIcon{
    transform: translateY(4px);
    padding: 4px 4px 3px;
    border: none;
    background-color: transparent;
    border-radius: 2px;
    transition: background-color .3s linear;
    &:hover{
      cursor: pointer;
      background-color: #0EC22B1A;
    }
    &:active{
      background-color: #0EC22B4D;
    }
    img{
      max-width: fit-content;
    }
}