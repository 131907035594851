@import '../../shared/config/palette.scss';
.leftNav {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 80px;
    min-width: 272px;
    display: flex;
    flex-direction: column;
    z-index: 998;
    height: 100vh;
    background: var(--bg, $bg);
    align-items: flex-start;
    border-right: 1px solid var(--button-disabled, $button-disabled);
    @media (max-width: $mobile-dashboard) {
        display: none;
    }
    
    .link {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: none;
        padding-bottom: 16px;
        padding-top: 16px;
        padding-left: 17px;
        color: var(--menu-text, $menu-text);
        transition: all .3s linear;
        .img{
            margin-right: 17px;
            fill: var(--text-secondary, $text-secondary);
            transition: all .3s linear;
        }
        &:visited{
            color: var(--menu-text, $menu-text);
        }
        &:hover{
            color: var(--primary, $primary);
            background: var(--input-bg, $input-bg);
            .img{
                filter: grayscale(0);
                fill: var(--primary, $primary);
            }
        }
        &.active {
            color: var(--primary, $primary);
            background: var(--hover-green, $hover-green);
            .img{
                fill: var(--primary, $primary);
            }
        }
    }
    .powered {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        max-width: calc(100% - 24px);
        width: max-content;
        text-align: center;

        a {
            color: var(--primary, $primary);
            font-weight: 700;
            text-decoration: none;

            span {
                color: var(--text-dark, $text-dark);
            }
        }
    }

}
