@import '../../../shared/config/palette';

.nav {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--bg, $bg);
    max-height: 56px;
    width: 100vw;
    z-index: 999;
    padding: 10px 36px 10px 24px;
    border-bottom: 1px solid var(--button-disabled, $button-disabled);

    .logo {
        display: flex;
        align-items: center;
        background: none;
        border: none; 
        cursor: pointer;       

        img {
            margin-right: 8px;
        }

        .logoText {
            width: 205px;
            height: 36px;
            @media (max-width: $mobile-dashboard) {
                display: none;
            }
        }

        .mainLogo{
            width: 45px;
            height: 45px;
        }
        
        .customLogo{
            max-width: 266px;
            width: 100%;
            height: 36px;
            img{
                max-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .rightBlock {
        display: flex;
        align-items: center;

        .signIn {
            background-color: var(--input-bg, $input-bg);
            padding: 12px 24px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--menu-text, $menu-text);
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
            transition: background-color .3s linear;

            img {
                margin-left: 9px;
            }

            &:hover {
                background-color: var(--button-disabled, $button-disabled);
            }
        }

        .mobileMenuIcon {
            display: none;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 5px;
            width: 30px;
            height: 30px;

            &:active {
                background: rgba(44, 44, 44, 0.149);
            }

            @media (max-width: $mobile-dashboard) {
                display: inline-block;
            }
        }
    }
}