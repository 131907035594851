@import '../../../../shared/config/palette.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    overflow: auto;
    background: var(--bg, $bg);
    border-radius: 8px;
    padding: 20px;
    align-items: normal;
    text-align: left;
    border: 1px solid var(--button-disabled, $button-disabled);
    cursor: pointer;

    .image {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 293px;
        max-width: 100%;

        @media (max-width: $mobile) {
            min-height: 180px;
        }

        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 0;
            max-width: 100%;
            object-fit: cover;
        }

        .loading {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--light-green, $light-green);
            background: linear-gradient(var(--light-green, $light-green) 0%, var(--input-bg, $input-bg) 60%, var(--primary, $primary) 100%);
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .blackout {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #0000008c 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, #000000c2 100%);
        }

        .tags {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            flex-wrap: wrap;
            margin-top: 20px;
            padding: 0 20px;
            z-index: 2;

            .tag {
                border-radius: 6px;
                padding: 4px 16px;
                background: var(--primary-dark, $primary-dark);
                font-size: 14px;
                line-height: 24px;
                color: var(--bg, $bg);
                letter-spacing: 0.8px;
            }
        }

        .name {
            padding: 0 30px;
            margin-top: auto;
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            color: var(--bg, $bg);
            z-index: 2;
            word-wrap: break-word;
            max-width: 100%;

            @media (max-width: $tablet) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .location {
            color: var(--bg, $bg);
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 30px;
            padding: 0 35px;
            z-index: 2;
        }
    }

    .progress {
        margin-top: 16px;

        .digits {
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 8px;
            color: var(--primary, $primary);

            @media (max-width: $tablet) {
                font-size: 20px;
                line-height: 28px;
            }

            .target {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: var(--text-secondary, $text-secondary);
                display: inline-block;

                @media (max-width: $tablet) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .prices {
            border: 1px solid var(--input-bg, $input-bg);
            border-radius: 8px;
            background-color: var(--light-green, $light-green);
            padding: 20px;

            .row {
                display: flex;
                justify-content: space-between;
                font-size: 16px;

                @media (max-width: $tablet) {
                    flex-direction: column;
                }

                .name {
                    color: $tag-text;
                    margin-right: 12px;
                    font-weight: 400;
                    line-height: 24px;
                }

                .value {
                    color: var(--text-dark, $text-dark);
                    font-weight: 700;
                    line-height: 24px;
                }
            }
        }
    }

    &:hover{
        .button{
            background-color: var(--primary-dark, $primary-dark);
        }
    }

    .button {
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        padding-top: 15px;
        padding-bottom: 13px;
        border-radius: 0;
        margin-top: 16px;
        text-transform: none;
        border-radius: 8px;

        @media (max-width: $tablet) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}