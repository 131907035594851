.wrapper {
    position: relative;
}
.loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    background-color: white;
    transition: opacity .75s, visibility 1s;
}
.hide{
    opacity: 0;
    visibility: hidden;
}
