@import '../../../shared/config/palette.scss';

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
}

.back {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: var(--text-secondary, $text-secondary);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
    cursor: pointer;
    img{
        margin-right: 10px;
    }
}

.topPanel {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
    padding: 16px 0px 16px 16px;

    .text {
        color: var(--menu-text, $menu-text);
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 8px;
    }
}

.panel{
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
    padding: 16px 0px 16px 16px;
    margin-right: 10px;
}
