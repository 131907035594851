@import '../../../shared/config/palette.scss';

.title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    color: var(--menu-text, $menu-text);
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
}

.wrapper {
    border: 1px solid var(--button-disabled, $button-disabled);
    border-radius: 8px;
    margin-bottom: 32px;
    overflow: hidden;
    background-color: var(--bg, $bg);

    .emptyBlock {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--primary, $primary);
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .table_container {
         

        .table {
            .head {
                background: var(--table-head-bg, $table-head-bg);

                th {
                     
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    background: var(--input-bg, $input-bg);
                    padding: 16px 20px;
                    color: var(--text-secondary, $text-secondary);

                    .flexHead {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .left {
                        justify-content: flex-start;
                    }
                }
            }

            .firstLoading {
                display: flex;
                padding: 150px;
                align-items: center;
                justify-content: center;
            }

            tbody {
                position: relative;

                .loadingCell {
                    padding: 0;
                    border: none;

                    .loading {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg, $bg);
                        z-index: 1;
                    }
                }

                tr{
                    transition: background-color .3s linear;
                }

                tr:hover{
                    outline: 1px solid var(--primary, $primary);
                    background-color: var(--light-green, $light-green);
                }

                td {
                     
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--text, $text);
                    padding: 16px 20px;
                    min-height: 59px;
                    max-width: 202px;

                    .date {
                        font-size: 14px;
                        line-height: 20px;
                    }
    
                    .time {
                        font-size: 12px;
                        line-height: 16px;
                        text-align: left;
                        color: var(--text-secondary, $text-secondary);
                    }

                    .image {
                        display: block;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .pagination p,
    .pagination :global(.MuiSelect-select) {
         
        color: var(--text-secondary, $text-secondary);
    }
}

:global(.MuiTablePagination-root .MuiToolbar-root) {
     

    @media (max-width: $mobile) {
        font-size: 16px;

        p {
            font-size: 16px;
        }

        padding: 16px 0;
        flex-direction: column;
        width: 100%;

        :global(.MuiTablePagination-actions) {
            margin-left: 0;
        }

        :global(.MuiInputBase-root) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}