@import '../../shared/config/palette.scss';


.wrapper {
    position: relative;
    min-height: 100%;
    width: 50%;
    background: rgba(0, 0, 0, 0.58);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $tablet) {
        width: 100%;
        height: unset;
        min-height: 218px;
        background: rgba(0, 0, 0, 0.58);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;

        @media (max-width: $tablet) {
            display: none;
        }
    }

    .loading {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgb(222, 211, 211);
        background-image: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(196, 196, 196) 100%);
        transition: opacity .75s, visibility 1s;
    }
    .hide{
        opacity: 0;
        visibility: hidden;
    }

    .bgMobile {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        display: none;

        @media (max-width: $tablet) {
            display: block;
        }
    }

    .logo {
        margin-bottom: 48px;
        max-height: 80px;
        max-width: 371px;
        @media (max-width: $tablet) {
            margin: 0 auto;
            margin-top: 48px;
            margin-bottom: 24px;
            max-width: calc(100% - 24px);
        }

        img+img {
            margin-left: 14px;
        }
    }

    .tagline {
        font-size: 32px;
        font-weight: 300;
        line-height: 36px;
        color: var(--bg, $bg);
        text-align: center;
        padding: 0 8px;

        @media (max-width: $tablet) {
            text-align: center;
            margin-top: 0;
            line-height: 32px;
            font-size: 24px;
            margin-bottom: 40px;
        }

        @media (max-width: $mobile) {
            line-height: 28px;
            font-size: 20px;
        }
    }
}