@import '../../../shared/config/palette.scss';

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--menu-text, $menu-text);
}

.back {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: var(--text-secondary, $text-secondary);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
    cursor: pointer;

    img {
        margin-right: 10px;
    }
}

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 40px;

    .topPanel {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            color: var(--text-secondary, $text-secondary);
            margin-bottom: 22px;
            margin-top: 40px;
        }

        .deposit {
            font-size: 34px;
            font-weight: 600;
            line-height: 24px;
            color: var(--text-dark, $text-dark);
            margin-bottom: 63px;
        }
        .button {
            outline: none;
            border: none;
            width: auto;
            padding: 12px 24px;
            background: var(--input-bg, $input-bg);
            color: var(--menu-text, $menu-text);
            border-radius: 8px;
            font-size: 12px;
            line-height: 16px;
            text-transform: none;
            margin: 0 5px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &:hover {
                cursor: pointer;
                background: var(--button-disabled, $button-disabled);
            }

            &:disabled {
                color: var(--button-disabled, $button-disabled);
                background: var(--input-bg, $input-bg);
                cursor: not-allowed;

                img {
                    filter: opacity(0.5);
                }
            }

            img {
                margin-right: 9px;
            }

            @media (max-width:900px) {
                justify-content: center;
                width: 100%;
                margin-top: 16px;
            }
        }
    }
}

.table {
    @media (max-width:$tablet) {
        border-top: 1px solid var(--button-disabled, $button-disabled);
    }

    .titleRow {
        display: grid;
        grid-template-columns: 236px repeat(3, minmax(70px, 1fr));
        grid-column-gap: 0.5rem;
        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);

        @media (max-width:$tablet) {
            grid-template-columns: 1fr;
            justify-items: center;
        }

        .orderButton {
            padding: 5px;
        }

        .columnLeftTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                width: fit-content;
                flex: 0 1 auto;

                &.noSort {
                    display: none;
                }
            }
        }

        .rightsTitles {
            display: flex;
            width: calc(100% - 250px);
            justify-content: space-between;
        }

        .columnRightTitleStatus {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                &.noSort {
                    display: none;
                }
            }
        }
    }

    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 40px;

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}