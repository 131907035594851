@import '../../../shared/config/palette.scss';

.assetHighlights {
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
  
    .title {
      font-size: 28px;
      line-height: 42px;
      color: var(--menu-text, $menu-text);
      font-weight: 700;
      margin-bottom: 4px;
  
      @media(max-width: 768px) {
        font-size: 24px;
      }
    }
  
    .highlightsList {
      padding-left: 20px;
    }
  
    .highlightItem {
      font-size: 18px;
      line-height: 40px;
      color: var(--text-secondary, $text-secondary);
  
      @media(max-width: 768px) {
        font-size: 16px;
      }
    }
  }