@import '../../../shared/config/palette.scss';

.wrapper {
    height: 100vh;
    min-height: 100vh;
    display: flex;

    @media (max-width: $tablet) {
        flex-direction: column;
        height: unset;
    }

    .rightBlock {
        width: 50%;
        min-height: 100%;
        position: relative;
        overflow-y: scroll;
        background: var(--bg, $bg);

        @media (max-width: $tablet) {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            overflow-y: initial;
            width: 100%;
            height: 100%;
        }

        .warning {
            box-sizing: border-box;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--bg, $bg);
            background-color: $attention-red;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 10px;

            img {
                margin-right: 14px;
            }
        }

        .data {
            box-sizing: border-box;
            max-width: 720px;
            margin: 0 auto;
            width: 100%;
            padding: 80px;
            padding-top: 100px;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            @media (max-width: $tablet) {
                height: unset;
                padding: 40px;
                padding-top: 68px;
                padding-bottom: 80px;
            }

            @media (max-width: $mobile) {
                padding: 20px;
                padding-top: 88px;
                padding-bottom: 80px;
            }

            .back {
                position: absolute;
                top: 80px;
                left: 70px;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: var(--text-secondary, $text-secondary);
                font-weight: 600;
                line-height: 18px;
                transition: .3s;
                border-radius: 5px;

                img {
                    margin-right: 8px;
                }

                &:hover {
                    background-color: var(--input-bg, $input-bg);
                }

                @media (max-width: $tablet) {
                    top: 24px;
                    left: 40px;
                }

                @media (max-width: $mobile) {
                    left: 20px;
                }
            }

            &>header {
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                color: var(--primary, $primary);
                margin-bottom: 8px;

                @media (max-width: $mobile) {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .text,
            .subText {
                margin-top: 16px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
            }

            .subText {
                font-size: 14px;
                line-height: 20px;
            }

            .inputsBlock {
                margin-top: 8px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;


                .inputLabel {
                    color: var(--text-secondary, $text-secondary);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    margin-top: 16px;
                }

                .input {

                    border-radius: 8px;
                    margin-top: 8px;
                    margin-bottom: 0;

                    :global(.MuiOutlinedInput-root) {
                        background-color: var(--input-bg, $input-bg);
                        border-radius: 8px;
                    }

                    p {
                        margin: 0;
                        color: $attention-red;
                        font-size: 12px;
                        line-height: 16px;
                        padding-top: 8px;
                    }

                    fieldset {
                        border: 1px solid var(--input-bg, $input-bg);
                        border-radius: 8px;
                    }

                    :global(.Mui-error) fieldset {
                        border-color: $attention-red;
                    }

                    :global(.Mui-focused) fieldset {
                        border-color: var(--primary-dark, $primary-dark);
                    }

                    input {
                        height: 24px;
                        padding: 16px;
                        font-size: 14px;
                    }
                }

                .submitButton {
                    margin-top: 24px;
                    padding: 16px 32px;
                    background: var(--primary, $primary);
                    color: var(--bg, $bg);
                    border-radius: 8px;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: none;

                    &:hover {
                        cursor: pointer;
                        background: var(--primary-dark, $primary-dark);
                    }

                    &:disabled {
                        color: var(--button-disabled, $button-disabled);
                        background: var(--input-bg, $input-bg);
                    }
                }

                .signUp {
                    margin-top: 8px;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--primary, $primary);
                    width: max-content;
                    align-self: center;

                    &:hover {
                        color: var(--primary-dark, $primary-dark);
                    }
                }

                .reminder {
                    margin-top: 16px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    color: var(--text-secondary, $text-secondary);
                }

                .forgot {
                    margin-top: 8px;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: right;
                    color: var(--primary, $primary);
                    width: max-content;
                    align-self: flex-end;

                    &:hover {
                        color: var(--primary-dark, $primary-dark);
                    }
                }
            }

            .resend {
                font-size: 14px;
                font-weight: 600;
                margin-top: 78px;

                @media (max-width: $tablet) {
                    display: flex;
                    flex-direction: column;
                }

                .button {
                    text-transform: none;
                    line-height: 24px;
                    color: var(--primary, $primary);
                    padding: 0;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    align-self: flex-start;

                    &:hover {
                        background-color: transparent;
                        color: var(--primary-dark, $primary-dark);
                    }

                    &:disabled {
                        color: var(--text-grey, $text-grey);
                    }
                }
            }
        }
    }
}

.errorText {
    margin: 0;
    color: $attention-red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
}