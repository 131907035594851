@import '../palette.scss';
.inputLabel {
    color: var(--text-secondary, $text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 16px;
}
.selectBlock{
    width: 49%;
    @media (max-width: $tablet) {
        width: 100%;
    }
}
.select {
    width: 100%;
    border-radius: 8px;
    margin-top: 0px;
    margin-bottom: 0;
    background-color: var(--input-bg, $input-bg);
    border-radius: 8px;

    fieldset {
        border: 1px solid var(--input-bg, $input-bg);
        border-radius: 8px;
    }
    :global(.MuiSelect-select) {
        padding: 8px 16px;
        color: var(--menu-text, $menu-text);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    em{
        color: var(--text-secondary, $text-secondary);
    }
}

.inputBlock {
    :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
        border-color: var(--primary, $primary);
    }
    width: 49%;
    @media (max-width: $tablet) {
        width: 100%;
    }
    .input {
        border-radius: 8px;
        margin-bottom: 0;
        margin-top: 0;
        width: 100%;

        :global(.MuiOutlinedInput-root) {
            background-color: var(--input-bg, $input-bg);
            border-radius: 8px;
            padding: 8px 16px;
            font-size: 14px;
            color: var(--menu-text, $menu-text);
        }
        :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
            border-color: var(--primary, $primary);
        }
        :global(.MuiAutocomplete-input.MuiOutlinedInput-input) {
            padding: 0;
        }

        p {
            margin: 0;
            color: $attention-red;
            font-size: 12px;
            line-height: 16px;
            padding-top: 8px;
        }

        fieldset {
            border: 1px solid var(--input-bg, $input-bg);
            border-radius: 8px;
        }

        :global(.Mui-error) fieldset {
            border-color: $attention-red;
        }

        :global(label.Mui-focused) {
            color: var(--primary, $primary);
        }

        input {
            height: 24px;
            padding: 0;
            font-size: 14px;
            color: var(--menu-text, $menu-text);
        }
        
    }
}
.fullwidth{
    width: 100%;
}