@import '../../../shared/config/palette.scss';

.title {
    font-size: 24px;
    color: var(--menu-text, $menu-text);
    font-weight: 600;
    line-height: 32px;
}

.back {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: var(--text-secondary, $text-secondary);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
    cursor: pointer;
    img{
        margin-right: 10px;
    }
}
