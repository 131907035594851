@import '../../../shared/config/palette.scss';

.assetInfo {
    background: var(--bg, $bg);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);

    .assetTitle {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: $mobile-dashboard) {
            flex-direction: column-reverse;
        }

        .title {
            font-size: 36px;
            font-weight: 700;
            color: var(--menu-text, $menu-text);
            line-height: 44px;
            text-align: left;
            margin-bottom: 5px;
            span{
                color: var(--primary, $primary);
                text-transform: uppercase;
            }

            @media (max-width: $mobile-dashboard) {
                font-size: 24px;
                width: 85%;
                margin-bottom: 2px;
                line-height: 32px;
            }
        }

        .location {
            font-size: 16px;
            margin-bottom: 10px;
            color: var(--text, $text);

            @media (max-width: $mobile-dashboard) {
                font-size: 14px;
                margin-bottom: 16px;
            }
        }

        .descr {
            font-size: 18px;
            line-height: 28px;
            color: var(--text, $text);
            margin-bottom: 15px;
            max-width: 720px;

            @media (max-width: $mobile-dashboard) {
                font-size: 16px;
            }
        }
    }

    .assetInfoMain {
        display: flex;
        justify-content: space-between;
        min-height: 410px;

        @media (max-width: $tablet-asset) {
            flex-direction: column;
        }

        .left {
            width: 60%;
            @media (max-width: $tablet-asset) {
                order: 2;
                width: 100%;
            }
            .assetImage {
                height: 406px;
                width: 100%;
                position: relative;
    
                .loading {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 406px;
                    animation-duration: 1.7s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-name: placeholderAnimate;
                    background: #f6f7f8;
                    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
                    background-size: 1300px;
                    transition: opacity .75s, visibility 1s;
                }
                .hide{
                    opacity: 0;
                    visibility: hidden;
                }
    
                @keyframes placeholderAnimate {
                    0% {
                        background-position: -650px 0;
                    }
                    100% {
                        background-position: 650px 0;
                    }
                }
                iframe {
                    width: 100%;
                    height: 406px;
                    border-radius: 8px;
                }
    
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    border-radius: 8px;
                }
            }
        }

        .right {
            width: 40%;
            position: relative;
            overflow: hidden;

            @media (max-width: $tablet-asset) {
                order: 1;
                width: 100%;
            }
        }

        .tags {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;

            .tagItem {
                background-color: $tag-bg;
                color: $tag-text;
                border-radius: 6px;
                margin-right: 10px;
                font-size: 14px;
                padding: 4px 10px;
            }
        }
    }
}