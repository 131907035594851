@import '../login/style.module.scss';
@import '../../../shared/config/palette.scss';
.data {
  align-items: baseline;
}
.text {
  .email {
    color: var(--primary, $primary);
    font-weight: 600;
  }
}
.buttonBlock {
  position: relative;
  .resendBtn {
    padding: 16px 32px;
    background: var(--primary, $primary);
    color: var(--bg, $bg);
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;

    &:hover {
      cursor: pointer;
      background: var(--primary-dark, $primary-dark);
    }

    &:disabled {
      color: var(--button-disabled, $button-disabled);
      background: var(--input-bg, $input-bg);
    }
  }
  .sent {
    color: var(--text-secondary, $text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin-top: 16px;
    position: absolute;
    bottom: -30px;
    left: 0;
    &.hidden {
      display: none;
    }
  }
}
