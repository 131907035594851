@import '../../../shared/config/palette.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	.listHighlights {
		display: flex;
		flex-direction: column;
		margin-top: 16px;

		.perk {
			display: flex;
			padding-bottom: 16px;

			.left {
				width: 100%;
				padding: 10px;
				border-radius: 8px;
				border: 1px solid var(--button-disabled, $button-disabled);
				position: relative;
				.right{
					position: absolute;
					top: 5px;
					right: 5px;
					height: 100%;
					padding-bottom: 10px;
				}
			}
		}
	}
}

.addButton {
	width: max-content;
	color: var(--primary, $primary);
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	border: none;
	background: transparent;
	transition: background-color .3s linear;

	&:hover {
		background-color: var(--hover-green, $hover-green);
	}
}