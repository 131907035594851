@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,400;0,600;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import 'shared/config/palette';
@import 'app/styles/reusable/text.scss';
@import 'app/styles/reusable/containers.scss';

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Montserrat';
  h2, h3, h4{
    color: var(--text, $text);
  }
  button, input{
    font-family: 'Montserrat';
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}