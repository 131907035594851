@import '../../../../shared/config/palette.scss';

.wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .status {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 6px;
        color: var(--primary, $primary);

        &.weak {
            color: $attention-red;
        }

        &.medium {
            color: $attention-yellow;
        }
    }

    .requirements {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
    }

    .points {

        .item {
            display: flex;
            align-items: center;
            min-height: 20px;
            font-size: 14px;
            line-height: 20px;
            color: var(--text, $text);

            img {
                filter: grayscale(1);
                transition: .3s;
                margin-right: 12px;
                align-self: start;

                &.active {
                    filter: none;
                }
            }
        }
    }
}