@import '../../../shared/config/palette.scss';

.inputLabel {
    color: var(--text-secondary, $text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 16px;
}

.inputBlock {
    width: 49%;

    @media (max-width: $tablet) {
        width: 100%;
    }

    .input {
        border-radius: 8px;
        box-sizing: border-box;
        margin-bottom: 0;
        margin-top: 0;
        width: 100%;
        color: var(--text, $text);;
        background: var(--input-bg, $input-bg);
        border: 1px solid var(--input-bg, $input-bg);
        border-radius: 0px 0px 8px 8px;
        min-height: 40px;
        padding: 8px 16px;

        &:hover {
            border-color: var(--text, $text);
        }

        &:focus,
        :active {
            border-color: var(--primary, $primary);
        }

        p {
            font-size: 14px;
            color: var(--menu-text, $menu-text);

        }
    }
}

.toolBar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 2px solid var(--input-bg, $input-bg);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px 8px 0px 0px;

    .btn {
        margin: 0px 5px;
        background: none;
        color: var(--text-secondary, $text-secondary);
        outline: none;
        border: none;
        cursor: pointer;
        transition: background-color .3s linear;

        &:hover {
            color: var(--text, $text);
        }
        &.active {
            color: var(--bg, $bg);
            background-color: $primary;
        }
    }
}

.fullwidth {
    width: 100%;
}

.selected{
    box-shadow: 0 0 0 3px #ddd;
}