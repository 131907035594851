@import "../../../shared/config/palette";

.wrapper{
  margin-top: 16px;
  background-color: var(--bg, $bg);
  border-radius: 8px;
  border: 1px solid var(--button-disabled, $button-disabled);
  padding: 24px;
  color: var(--text, $text);
  font-weight: 500;
  .title{
    font-weight: 600;
    font-size: 1.3rem;
  }
  .activeRow{
    background-color: var(--light-green, $light-green);
  }
  .activeCellTitle{
    color: var(--primary, $primary);
  }
}