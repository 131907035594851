@import "../../../shared/config/palette";

.backBtn{
  color: var(--text-secondary, $text-secondary);
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 24px;
  gap: 10px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
}