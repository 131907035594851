@import '../../../shared/config/palette.scss';

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 24px;
}

.topPanel {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px 0px 16px 16px;
    margin-right: 10px;

    .text {
        color: var(--menu-text, $menu-text);
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.table {
    @media (max-width:900px) {
        border-top: 1px solid var(--button-disabled, $button-disabled);
    }

    .titleRow {
        display: grid;
        grid-template-columns: 150px 150px repeat(2, minmax(90px, 1fr));
        grid-column-gap: 0.5rem;

        @media (max-width:900px) {
            display: none;
        }

        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);

        .orderButton {
            padding: 5px;
        }

        .columnTitle {
            padding: 16px;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;

        .profileRow {
            display: grid;
            grid-template-columns: 150px 150px repeat(2, minmax(90px, 1fr));
            grid-column-gap: 0.5rem;
            outline-color: white;
            border-bottom: 1px solid var(--button-disabled, $button-disabled);
            transition: all 0.2s linear;

            &:hover {
                outline: 1px solid var(--primary, $primary);
                background: #00bc6812;
            }

            @media (max-width:900px) {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            .column {
                padding: 16px;
                color: var(--menu-text, $menu-text);

                .mobileDescription {
                    color: var(--text, $text);
                    font-size: 13px;
                    margin-right: 5px;
                    display: none;

                    @media (max-width: 900px) {
                        display: inline;
                    }
                }

                .date {
                    font-size: 14px;
                    line-height: 20px;
                }

                .time {
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: var(--text-secondary, $text-secondary);
                }

                .text {
                    font-size: 14px;
                    line-height: 20px;

                    @media (max-width:$tablet) {
                        word-break: break-all;
                    }
                }
            }

            .right {
                display: flex;
                display: flex;
                justify-content: flex-end;
            }
        }

        :global(.MuiPaper-root) {
            border-radius: 8px;

            :global(.MuiList-root) {
                li {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .pagination {
        color: var(--text-secondary, $text-secondary);
         
    }
}