@import '../../../shared/config/palette.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh - 56px);

    header {
        display: flex;
        align-items: stretch;
        gap: 32px;
        padding: 0 48px;
        margin-top: -28px;

        .field {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
            background-color: var(--bg, $bg);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            .value {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                margin-top: 8px;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--primary, $primary);
                }
            }

            .name {
                font-size: 14px;
                line-height: 20px;
                color: var(--text-grey, $text-grey);
            }
        }
    }

    main {
        padding: 44px 49px 64px 42px;
        display: flex;
        gap: 38px;
        background: var(--input-bg, $input-bg);
        flex-grow: 1;

        .left {
            width: calc(100% - 323px);
            display: flex;
            flex-direction: column;
        }

        .right {
            width: 285px;
            display: flex;
            flex-direction: column;
        }

        .block {
            .title {
                color: var(--menu-text, $menu-text);
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin-top: 20px;
                margin-bottom: 9px;
            }

            .first {
                display: flex;
                gap: 19px;

                .image {
                    background-color: var(--bg, $bg);
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    padding: 15px;

                    img {
                        display: block;
                    }
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    flex-grow: 1;

                    .item {
                        background-color: var(--bg, $bg);
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        padding: 15px;
                        padding-left: 30px;
                        padding-right: 25px;

                        display: grid;
                        grid-template-columns: auto 105px;
                        grid-template-rows: 20px 27px;
                        grid-template-areas:
                            "n g"
                            "v g";
                        grid-gap: 3px;

                        .name {
                            grid-area: n;
                        }

                        .value {
                            grid-area: v;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 27px;
                        }

                        .graph {
                            grid-area: g;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 5px;
                            overflow: hidden;
                        }

                        &.distribution {
                            grid-template-columns: auto 120px;

                            .graph {
                                text-transform: none;
                                background-color: var(--primary, $primary);
                                color: var(--bg, $bg);
                            }
                        }
                    }
                }
            }

            .tokenInfo {
                display: flex;
                flex-direction: column;

                background-color: var(--bg, $bg);
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 10px;

                .item {
                    padding: 15px;
                    border-bottom: 1px solid var(--input-bg, $input-bg);

                    .name {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17px;
                    }

                    .value {
                        font-size: 14px;
                        line-height: 17px;

                        button {
                            padding: 0;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

}