@import "../../shared/config/palette";

.title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 133%;
  color: var(--menu-text, $menu-text);
  margin-bottom: 24px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.filterTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--text-secondary, $text-secondary);
}

.status {
  background: var(--primary, $primary);
  padding: 8px;
  border-radius: 8px;
  color: var(--text-dark, $text-dark);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;

  &.new {
    background: #f5cdf4;
    color: #a92db4;
  }

  &.completed {
    background: #cdf5e2;
    color: #2db473;
  }

  &.pending {
    background: #f6efcd;
    color: #d38612;
  }

  &.inProgress {
    background: #cde5f5;
    color: #2d7bb4;
  }

  &.cancelled,
  &.aborted,
  &.failed {
    background: #f6cdcd;
    color: #b44e2d;
  }
}

.select {
  height: 40px;
  border-radius: 8px;
  margin-bottom: 0;
  background-color: var(--input-bg, $input-bg);
  border-radius: 8px;
  color: var(--menu-text, $menu-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 171%;

  p {
    margin: 0;
    color: $attention-red;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
  }

  fieldset {
    border: 1px solid var(--input-bg, $input-bg);
    border-radius: 8px;
  }
}

.time {
  font-size: 12px;
  color: var(--text-secondary, $text-secondary)
}

.tableWrapper {
  .cells {
    td {
      padding: 16px;
    }
  }

  .pagination {
    color: var(--text-secondary, $text-secondary);
  }
}

.filterPanel {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width:1330px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .left {
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;

    @media (max-width:1330px) {
      padding-top: 16px;
      border-top: 1px solid var(--button-disabled, $button-disabled);
      width: 100%;
      margin-right: 0;
    }

    @media (max-width:900px) {
      padding: 16px;
    }

    .text {
      color: var(--text-secondary, $text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }

    margin-right: 10px;

    .select {
      border-radius: 8px;
      margin-bottom: 0;
      background-color: var(--input-bg, $input-bg);
      border-radius: 8px;
      color: var(--menu-text, $menu-text);
      width: 280px;
      max-width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      p {
        margin: 0;
        color: $attention-red;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
      }

      fieldset {
        border: 1px solid var(--input-bg, $input-bg);
        border-radius: 8px;
      }
    }

    :global(.MuiInputBase-input) {
      padding: 8px 16px;
    }
  }

  .left.double{
    display: flex;
    @media (max-width:1000px) {
      flex-direction: column;
      width: 100%;
      .block{
        margin: 5px;
      }
    }
    .block{
      margin: 0 5px;
    }
  }

  :global(.MuiOutlinedInput-root.Mui-focused) fieldset {
    border-color: var(--primary-dark, $primary-dark);
  }

  .right {
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    align-items: flex-start;

    @media (max-width:900px) {
      flex-direction: column;
      width: 100%;
    }
  }
}