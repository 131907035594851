@import '../../../shared/config/palette.scss';

.title {
    font-size: 24px;
    font-weight: 600;
    color: var(--menu-text, $menu-text);
    line-height: 32px;
}

.wrapper {
    background: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
    margin-top: 40px;
}

.confirm{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
    @media (max-width:$tablet) {
        flex-direction: column;
        align-items: center;
        .text{
            text-align: center;
            margin-bottom: 12px;
        }
    }
    .text {
        font-size: 16px;
        line-height: 22px;
        margin-right: 15px;
    }

    .submitButton {
        outline: none;
        border: none;
        width: 100%;
        max-width: 200px;
        padding: 14px 20px;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;
        text-transform: none;
        transition: background-color .3s linear;
    
        &:hover {
            cursor: pointer;
            background-color: var(--primary-dark, $primary-dark);
        }
    
        &:disabled {
            color: var(--button-disabled, $button-disabled);
            background-color: var(--input-bg, $input-bg);
            cursor: default;
        }
    }
}

.topPanel {
    .filterPanel {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @media (max-width:1330px) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }

        .right {
            box-sizing: border-box;
            padding: 16px;
            display: flex;
            align-items: flex-start;

            @media (max-width:900px) {
                flex-direction: column;
                width: 100%;
            }

            .button {
                outline: none;
                border: none;
                width: auto;
                padding: 12px 24px;
                background-color: var(--primary, $primary);
                color: var(--bg, $bg);
                border-radius: 8px;
                font-size: 12px;
                line-height: 16px;
                text-transform: none;
                margin: 0 5px;
                display: flex;
                align-items: center;
                transition: background-color .3s linear;

                &:hover {
                    cursor: pointer;
                    background-color: var(--primary-dark, $primary-dark);
                }

                &:disabled {
                    color: var(--button-disabled, $button-disabled);
                    background-color: var(--input-bg, $input-bg);
                    cursor: not-allowed;

                    img {
                        filter: opacity(0.5);
                    }
                }

                img {
                    margin-right: 9px;
                }

                @media (max-width:900px) {
                    justify-content: center;
                    width: 100%;
                    margin-top: 16px;
                }
            }
        }
    }
}

.table {
    @media (max-width:900px) {
        border-top: 1px solid var(--button-disabled, $button-disabled);
    }

    .titleRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: var(--input-bg, $input-bg);
        color: var(--text-secondary, $text-secondary);

        @media (max-width:$tablet) {
            justify-content: space-between;
        }

        .orderButton {
            padding: 5px;
        }

        .columnLeftTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 250px;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            @media (max-width:$tablet) {
                width: fit-content;
            }
        }

        .rightsTitles {
            display: flex;
            width: calc(100% - 250px);
            justify-content: space-between;
        }

        .columnRightTitle {
            padding: 16px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            width: calc(100% - 250px);

            @media (max-width:$tablet) {
                width: fit-content;
            }
        }
    }

    .profilesRows {
        display: flex;
        flex-direction: column;
        position: relative;

        .loadingCell {
            padding: 0;
            border: none;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg, $bg);
                z-index: 1;
            }
        }

        .emptyBlock {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--primary, $primary);
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}