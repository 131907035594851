@import '../../../shared/config/palette.scss';

.assetPresentation {
    border-radius: 8px;
    background-color: var(--light-green, $light-green);
    border: 1px solid var(--primary, $primary);
    padding: 29px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 26px 0;
    .ppt {
      width: 62px;
      margin-bottom: 14px;
    }
    .inner {
      display: flex;
      flex-direction: column;
      max-width: 335px;
      text-align: center;
      .title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 4px;
        color: var(--text-dark, $text-dark);
        @media (max-width: 768px) {
          font-size: 22px;
        }
      }
      .subtitle {
        font-size: 18px;
        color: var(--text-dark, $text-dark);
        margin-bottom: 16px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .enter {
        border: none;
        background-color: var(--primary, $primary);
        color: var(--bg, $bg);
        font-size: 20px;
        font-weight: 700;
        padding: 14px 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        &:hover {
          background-color: var(--primary-dark, $primary-dark);
          transition: all 0.2s ease-out;
        }
      }
      span{
          margin-top: 18px;
          font-size: 16px;
          color: $attention-red;
      }
    }
    
}