@import "../../../shared/config/palette";

.wrapper{
  .title{
    color: var(--text-dark, $text-dark);
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 120%;
    margin-bottom: 24px;
  }
  .body{
    background-color: var(--bg, $bg);
    border-radius: 8px;
    border: 1px solid var(--button-disabled, $button-disabled);
  }
}