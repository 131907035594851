@import '../../../shared/config/palette';

.wrapper {
  background-color: var(--bg, $bg);
  border-radius: 8px;
  border: 1px solid var(--button-disabled, $button-disabled);
  padding: 24px;
  color: var(--text, $text);
  font-weight: 500;

  .error{
    font-size: 12px;
    color: $attention-red;
    margin: -12px 0 0 0;
  }

  .button {
    background: var(--primary, $primary);
    color: var(--bg, $bg);
    text-decoration: none;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 auto;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 8px;
    width: max-content;
    transition: all 0.3s linear;
    &:disabled {
      background-color: var(--button-disabled, $button-disabled);
    }
    &:hover {
      cursor: pointer;
      background: var(--primary-dark, $primary-dark);
    }
  }
  .favError {
    position: relative;
    & > div > div > div > div > div {
      outline: 2px dashed $attention-red !important;
    }
    &::before {
      position: absolute;
      content: 'Upload favicon with right sizes';
      left: 0;
      bottom: -15px;
      color: $attention-red;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .imgWrapper {
    margin-top: 10px;
    border-radius: 8px;
    padding: 10px;
    border: 2px dashed var(--primary, $primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .img {
      flex: 0 1 50%;

      img {
        max-width: 100%;
        max-height: 200px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    button {
      flex: 0 1 150px;
      border-color: $attention-red;
      color: $attention-red;
      text-transform: inherit;
      &:hover {
        background-color: rgba(240, 85, 85, 0.06);
      }
      &:disabled {
        background-color: var(--button-disabled, $button-disabled);
      }
    }
  }
}
