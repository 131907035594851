@import '../../../shared/config/palette.scss';

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--text-dark, $text-dark);
    margin-bottom: 24px;
}

.list {
    display: flex;
    flex-direction: column;

    .notification {
        display: flex;
        flex-direction: column;
        color: var(--menu-text, $menu-text);
        padding: 8px 16px 8px 32px;
        text-decoration: none;
        background-color: var(--bg, $bg);
        transition: background-color .1s linear;
        position: relative;
        border-radius: 8px;
        font-size: 16px;
        margin-bottom: 8px;
        cursor: default;

        img {
            margin-right: 8px;
        }

        .time {
            margin-top: 8px;
            display: flex;
            color: var(--text-secondary, $text-secondary);
        }

        .buttons {
            display: flex;
            gap: 5px;
            margin-top: 8px;
            @media  (max-width: $mobile-dashboard) {
                max-width: 100%;
                flex-direction: column;
            }
        }
    }

    .notDone {
        &::after {
            content: '';
            width: 8px;
            height: 8px;
            color: var(--bg, $bg);
            background: var(--primary, $primary);
            border-radius: 50%;
            position: absolute;
            left: 16px;
            top: 13px;
            cursor: pointer;
        }
    }
}